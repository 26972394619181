import * as types from 'action_types'
import * as actions from 'actions'
import { mergeDeep, deepMerge } from 'reducers/z_redux_helpers'

const INITIAL_STATE = {
	uid: null,
	open: false,
  teamSize: 1,
  teamMemberId: 1,
  profile: {},
  submissionModeActive: false,
  stores: [],
	worklist: [],
	worklistOrder: {},
	renderedData: [],
	characteristics: [
	// 	{
	// 		key: 0,
	// 		name: 'bristol', // 1 -> 7
	// 		options: {
	// 			1: 1,
	// 			2: 2,
	// 			3: 3,
	// 			4: 4,
	// 			5: 5,
	// 			6: 6,
	// 			7: 7,
	// 		},
	// 		value: null,
	// 		guides: {
	// 			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/Bristol_Tall.jpg',
	// 			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Bristol_Large.jpg',
	// 		},
	// 	},
	// 	{
	// 		key: 1,
	// 		name: 'consistency', // Liquid to solid
	// 		options: {
	// 			0: 0,
	// 			1: 10,
	// 			2: 20,
	// 			3: 30,
	// 			4: 40,
	// 			5: 50,
	// 			6: 60,
	// 			7: 70,
	// 			8: 80,
	// 			9: 90,
	// 			'-': 100,
	// 		},
	// 		value: null,
	// 		guides: {
	// 			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/Consistency_Tall.jpg',
	// 			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Consistency_Large.jpg',
	// 		},
	// 	},
	// 	{
	// 		key: 2,
	// 		name: 'fragmentation', // few pieces or lots of pieces
	// 		options: {
	// 			0: 0,
	// 			1: 10,
	// 			2: 20,
	// 			3: 30,
	// 			4: 40,
	// 			5: 50,
	// 			6: 60,
	// 			7: 70,
	// 			8: 80,
	// 			9: 90,
	// 			'-': 100,
	// 		},
	// 		value: null,
	// 		guides: {
	// 			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/Fragmentation_Tall.jpg',
	// 			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Fragmentation_Large.jpg',
	// 		},
	// 	},
	// 	{
	// 		key: 3,
	// 		name: 'edge_fuzziness', //
	// 		options: {
	// 			0: 0,
	// 			1: 10,
	// 			2: 20,
	// 			3: 30,
	// 			4: 40,
	// 			5: 50,
	// 			6: 60,
	// 			7: 70,
	// 			8: 80,
	// 			9: 90,
	// 			'-': 100,
	// 		},
	// 		value: null,
	// 		guides: {
	// 			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/Fuzziness_Tall.jpg',
	// 			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Fuzziness_Large.jpg',
	// 		},
	// 	},
    
	// 	{
	// 		key: 4,
	// 		name: 'volume', //
	// 		options: {
	// 			0: 0,
	// 			1: 10,
	// 			2: 20,
	// 			3: 30,
	// 			4: 40,
	// 			5: 50,
	// 			6: 60,
	// 			7: 70,
	// 			8: 80,
	// 			9: 90,
	// 			'-': 100,
	// 		},
	// 		value: null,
	// 		guides: {
	// 			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/Volume_Tall.jpg',
	// 			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Volume_Large.jpg',
	// 		},
	// 	},
    // {
    //   key: 5,
    //   name: 'buoyancy', // sink vs float
    //   options: {
    //     0: 0,
    //     1: 1,
    //     2: 2,
    //   },
    //   value: null,
    //   guides: {
    //     tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/Buoyancy_Tall.jpg',
    //     large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Buoyancy_Large.jpg',
    //   },
    // },
    // {
    //   key: 6,
    //   name: 'diameter', // thin vs thick
    //   options: {
    //     0: 0,
    //     1: 1,
    //     2: 2,
    //   },
    //   value: null,
    //   guides: {
    //     tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/Diameter_Tall.jpg',
    //     large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Diameter_Large.jpg',
    //   },
    // },
	{
		key: 7,
		name: 'blood_amount',
		options: {
			0: 0,
			1: 10,
			2: 20,
			3: 30,
			4: 40,
			5: 50,
			6: 60,
			7: 70,
			8: 80,
			9: 90,
			'-': 100,
		},
		guides: {
			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/blood_amount_tall.jpg',
			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Diameter_Large.jpg',
		},
	},
	{
		key: 8,
		name: 'blood_color',
		options: {
			0: "bright red",
			1: "dark red",
			2: "maroon",
			3: "no blood",
		},
		guides: {
			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/blood_color_tall.jpg',
			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Diameter_Large.jpg',
		},
	},
	{
		key: 9,
		name: 'blood_inorout',
		options: {
			0: "mixed in stool",
			1: "coating stool",
			2: "separate from stool",
			3: "mixed + coating",
			4: "mixed + separate",
			5: "coating + separate",
			6: "mixed + coating + separate",
			7: "no blood",
		},
		guides: {
			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/blood_inorout_tall.jpg',
			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Diameter_Large.jpg',
		},
	},
	{
		key: 10,
		name: 'mucus_amount',
		options: {
			0: 0,
			1: 10,
			2: 20,
			3: 30,
			4: 40,
			5: 50,
			6: 60,
			7: 70,
			8: 80,
			9: 90,
			'-': 100,
		},
		guides: {
			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/mucus_amount_tall.jpg',
			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Diameter_Large.jpg',
		},
	},
	{
		key: 11,
		name: 'stool_inorout',
		options: {
			0: "in water",
			1: "in air",
			2: "both",
		},
		guides: {
			tall: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Tall/stool_inorout_tall.jpg',
			large: 'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Large/Diameter_Large.jpg',
		},
	},
	{
		key: 12,
		name: 'colo_prep',
		options: {
			0: 0,
			1: 10,
			2: 20,
			3: 30,
			4: 40,
			5: 50,
			6: 60,
			7: 70,
			8: 80,
			9: 90,
			'-': 100,
		},
		guides: {
			tall: '',
			large: '',
		},
	},
	],
	interestedCharacteristics: ['blood_amount'],
	visibleGuide: 'blood_amount',
	guidePreference: 'tall',
	currentDatabase: '',
	instructionsModalOpen: false,
	imageZoomPreference: 'cropped',
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.SET_LABELING_ENTRY_UID:
			return {
				...state,
				uid: action.payload,
			}

    case types.SET_LABELING_PROFILE:
      return {
        ...state,
        profile: action.payload,
      }

    case types.SET_LABELING_STORES:
      return {
        ...state,
        stores: action.payload,
      }

		case types.SET_LABELING_INSTRUCTIONS_VISIBLE:
			return {
				...state,
				instructionsModalOpen: action.payload,
			}

		case types.SET_LABELING_GUIDE_PREFERENCE:
			return {
				...state,
				guidePreference: action.payload,
			}

		case types.SET_LABELING_IMAGE_ZOOM_PREFERENCE:
			return {
				...state,
				imageZoomPreference: action.payload,
			}

		case types.SET_LABELING_TABLE_RENDERED:
			const orderedTable = (action.payload || []).reduce((acc, item, i) => {
				acc[item.id] = i
				return acc
			}, {})
			return {
				...state,
				renderedData: action.payload,
			}

		case types.SET_LABELING_WORKLIST:
			return {
				...state,
				worklist: action.payload,
				worklistOrder: action.payload.reduce((acc, item, i) => {
					acc[item.id] = i
					return acc
				}, {}),
			}

		case types.SET_LABELING_VISIBILITY:
			return {
				...state,
				open: action.payload,
			}

		case types.SET_LABELING_VISIBILE_GUIDE:
			return {
				...state,
				visibleGuide: action.payload,
			}

		case types.SET_INTERESTED_CHARACTERISTICS:
			return {
				...state,
				interestedCharacteristics: action.payload,
			}
    case types.TOGGLE_SUBMISSION_LOCK:
      if (action.payload !== undefined && typeof action.payload === "boolean" ) {
        return {
          ...state,
          submissionModeActive: action.payload
        }
      } else {
        return {
          ...state,
          submissionModeActive: !state.submissionModeActive
        }
      }

    case types.SET_TEAM_SIZE:
      return {
        ...state,
        teamSize: action.payload,
      }

    case types.SET_TEAM_MEMBER_ID:
      return {
        ...state,
        teamMemberId: action.payload,
      }
  
		case types.SET_LABELING_DB:
			return {
				...state,
				currentDatabase: action.payload,
			}

		case types.LOGOUT_USER_SUCCESS:
			return INITIAL_STATE

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		default:
			return state
	}
}
