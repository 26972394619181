import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import RefreshIcon from '@material-ui/icons/Refresh'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import Color from 'color'
import { SymptomScoreChart, PieComplianceChart, InterventionComplianceChart, SymptomScoresLifetime } from '@components'
import { getRange } from 'utils/helpers'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(3),
	},
	media: {
		height: 0,
		paddingTop: '36.25%', // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		'& > img': {
			objectFit: 'contain',
		},

		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	cover: {
		width: 151,
	},
	titleBar: {
		padding: theme.spacing(1),
	},
	interventionGroupHeader: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
      ${
			theme.palette.mode === 'dark'
				? Color(theme.palette.background.paper).lighten(0.7)
				: Color(theme.palette.background.paper).darken(0.2)
		} 3%,
      ${theme.palette.background.paper} 90%)`,
	},
	complianceHeader: {
		marginTop: 10,
		justifyContent: 'center',
		alignItems: 'center',
	},

	horizontalScroll: {
		flexGrow: 1,
		overflow: 'auto',
		height: '100%',
	},
	emptyGraph: {
		margin: theme.spacing(2),
		borderWidth: 1,
		borderStyle: 'solid',
		padding: theme.spacing(2),
	},
	emptyGraphContents: {
		padding: 10,
		textAlign: 'center',
	},
	chartsHolder: {
		marginLeft: 10,
		marginRight: 10,
	},
}))

const getComplianceSummary = (total, complied) => {
	const ratio = complied / total
	if (total < 1) {
		return 'N/A'
	}
	if (ratio > 0.8) {
		return 'Good'
	} else if (ratio > 0.6) {
		return 'Fair'
	} else if (ratio > 0.45) {
		return 'Half Compliant'
	} else if (ratio > 0.3) {
		return 'Poor'
	} else if (ratio > 0.15) {
		return 'Terrible'
	} else if (ratio > 0.05) {
		return 'Horrible'
	}
	return 'Did not comply'
}

function ActiveInterventionsTable(props) {
	const classes = useStyles()
	const [expanded, setExpanded] = React.useState(null)

	const handleExpandClick = (i) => {
		if (expanded === i) {
			setExpanded(null)
		} else {
			setExpanded(i)
		}
	}

	const filterActiveOnDate = (interv) => {
		const today = moment().format('YYYY-MM-DD')
		if (interv.completed_at && interv.occurred_at) {
			return moment(today).isBetween([
				moment(interv.occurred_at).startOf('day'),
				moment(interv.completed_at).endOf('day'),
			])
		} else {
			return moment(today).isSameOrAfter(moment(interv.occurred_at).startOf('day'))
		}
	}

	const {
		interventions,
		symptomScore,
		loading,
		onRefresh,
		hydrateInterventionCompliance,
		interventionCompliance,
	} = props

	const totalInterventions = [...interventions].filter(filterActiveOnDate)

	const compliances = totalInterventions.reduce((acc, activeIntervention) => {
		const interventionStart = moment(activeIntervention.occurred_at).startOf('day').format('YYYY-MM-DD')
		const interventionEnd = activeIntervention.completed_at
			? moment(activeIntervention.completed_at).startOf('day').format('YYYY-MM-DD')
			: false

		const now = new Date()
		const endStart = moment(interventionEnd || now)
			.startOf('day')
			.format('YYYY-MM-DD')

		let compliedCount = 0
		let notCompliedCount = 0
		const complianceRecord = (interventionCompliance.interventionMap[activeIntervention.id] || []).reduce(
			(acc, item) => {
				const day = moment(item.range_start).format('YYYY-MM-DD')
				acc[day] = item.compliance
				if (item.compliance === 'true') {
					compliedCount++
				} else if (item.compliance === 'false') {
					notCompliedCount++
				}
				return acc
			},
			getRange(interventionStart, endStart, 'days').reduce((acc, item) => {
				acc[item.format('YYYY-MM-DD')] = 'unknown'
				return acc
			}, {})
		)

		acc[activeIntervention.id] = {
			total: Object.keys(complianceRecord).length,
			complied: compliedCount,
			notComplied: notCompliedCount,
			unknown: Object.keys(complianceRecord).length - compliedCount - notCompliedCount,
			summary: getComplianceSummary(Object.keys(complianceRecord).length, compliedCount),
		}
		return acc
	}, {})

	useEffect(() => {
		totalInterventions.forEach((interv) => {
			hydrateInterventionCompliance(interv)
		})
	}, [totalInterventions.length])

	return (
		<>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs>
							<Typography variant="h5" color="textSecondary" component="h5">
								Active Experiments ({interventions.length})
							</Typography>
						</Grid>
						<Grid item>
							<IconButton color="inherit" onClick={onRefresh}>
								<RefreshIcon className={classes.block} color="inherit" />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Grid container direction="column" className={classes.horizontalScroll}>
				{!loading ? (
					interventions.map((intervention, i) => {
						const startDate = moment(intervention.occurred_at).format('YYYY-MM-DD')
						const endDate =
							intervention.completed_at && moment(intervention.completed_at).format('YYYY-MM-DD')
						const progressMade = startDate !== moment().format('YYYY-MM-DD')
						const chartData = []
						if (compliances[intervention.id]) {
							if (compliances[intervention.id].complied) {
								chartData.push({
									color: '#7db586',
									value: (
										compliances[intervention.id].complied / compliances[intervention.id].total
									).toFixed(2),
									name: 'Complied',
								})
							}
							if (compliances[intervention.id].unknown) {
								chartData.push({
									color: '#CACED8',
									value: (
										compliances[intervention.id].unknown / compliances[intervention.id].total
									).toFixed(2),
									name: 'Unknown',
								})
							}
							if (compliances[intervention.id].complied) {
								chartData.push({
									color: '#bf565f',
									value: (
										compliances[intervention.id].notComplied / compliances[intervention.id].total
									).toFixed(2),
									name: "Didn't Comply",
								})
							}
						}

						return (
							<Grid item key={i}>
								<Card className={classes.root} variant="outlined">
									<CardHeader
										avatar={
											<Avatar
												variant="square"
												className={classes.avatar}
												src={`https://dieta-tracker-media-production.s3-us-west-2.amazonaws.com/public/assets/interventions/${intervention.meta.type}.jpg`}
											/>
										}
										action={
											compliances[intervention.id] ? (
												<div className={classes.complianceHeader}>
													<Grid container direction="row">
														<Grid
															item
															style={{
																paddingRight: 20,
																height: 40,
																margin: 'auto',
															}}
														>
															<Typography variant="body2" component="p">
																Compliance: {compliances[intervention.id].summary}
															</Typography>
														</Grid>
														<Grid item>
															<PieComplianceChart values={chartData} />
														</Grid>
													</Grid>
												</div>
											) : (
												<></>
											)
										}
										title={intervention.meta.friendlyName}
										subheader={`Begun: ${moment(intervention.occurred_at).format('YYYY-MM-DD')}`}
									/>

									<CardContent>
										{intervention.creator_id ? (
											<Typography variant="h6" color="textSecondary" component="h6">
												Self Created Experiment:
											</Typography>
										) : (
											<></>
										)}
										<Grid container direction="row">
											<Grid item xs>
												<Typography
													variant="body2"
													color="textSecondary"
													component="p"
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														alignItems: 'center',
													}}
												>
													{intervention.recommendation}
												</Typography>
											</Grid>
											<Grid item>
												<IconButton
													className={classNames(classes.expand, {
														[classes.expandOpen]: expanded,
													})}
													onClick={() => handleExpandClick(i)}
													aria-expanded={expanded}
													aria-label="show more"
												>
													<ExpandMoreIcon />
												</IconButton>
											</Grid>
										</Grid>
									</CardContent>
									{progressMade ? (
										<>
											<Collapse in={expanded === i} timeout="auto" unmountOnExit>
												<CardContent>
													{startDate === endDate ? (
														<div className={classes.emptyGraph}>
															<Typography
																variant="body1"
																color="textSecondary"
																component="body1"
															>
																Symptom Score Trend:
															</Typography>
															<br />
															<div className={classes.emptyGraphContents}>
																<Typography
																	variant="body2"
																	color="textSecondary"
																	component="body2"
																>
																	Unavailable: Immediately Ended after Started :(
																</Typography>
															</div>
														</div>
													) : (
														<div className={classes.chartsHolder}>
															<SymptomScoreChart
																scores={symptomScore.daysScores}
																range={{
																	start: startDate,
																	end: endDate || moment().format('YYYY-MM-DD'),
																}}
																regions={(
																	interventionCompliance.interventionMap[
																		intervention.id
																	] || []
																).reduce((acc, interv) => {
																	if (interv.compliance === 'true') {
																		acc[
																			moment(interv.range_start).format(
																				'YYYY-MM-DD'
																			)
																		] = {
																			range_start: interv.range_start,
																			Compliance: 100,
																		}
																	} else if (interv.compliance === 'false') {
																		acc[
																			moment(interv.range_start).format(
																				'YYYY-MM-DD'
																			)
																		] = {
																			range_start: interv.range_start,
																			'Non-Compliance': 100,
																		}
																	}

																	return acc
																}, {})}
															/>
														</div>
													)}
												</CardContent>
											</Collapse>
										</>
									) : (
										<></>
									)}
								</Card>
							</Grid>
						)
					})
				) : (
					<Grid container justifyContent="center" className={classes.root}>
						<CircularProgress />
					</Grid>
				)}
			</Grid>
		</>
	)
}

export { ActiveInterventionsTable }
