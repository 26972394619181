import React, { useEffect, useState } from 'react'
import * as storage from 'localforage' // defaults to localStorage for web
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden from '@material-ui/core/Hidden'
import Navigator from 'organisms/Navigator'
import NotificationsPanel from 'organisms/NotificationsPanel'
import Header from 'organisms/Header'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@material-ui/lab'

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
const drawerWidth = 226
const panelWidth = 350

const styles = (theme) => ({
	root: {
		display: 'flex',
		minHeight: '100vh',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	panel: {
		[theme.breakpoints.up('sm')]: {
			width: panelWidth,
			flexShrink: 0,
		},
	},
	appContent: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	mainContent: {
		flex: 1,
		padding: '0px',
	},
})

function Layout({ classes, location, children, pageContext = { titleKey: 'home', layout: true } }) {
	const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)
	const [showAlert, setAlert] = useState(false)

	useEffect(() => {
		;(async () => {
			setAlert(await storage.getItem('betaUnderstood'))
		})()
	}, [showAlert])

	const onDrawerToggle = () => setMobileDrawerOpen(!mobileDrawerOpen)

	const [mobilePanelOpen, setMobilePanelOpen] = useState(false)
	const onPanelToggle = () => setMobilePanelOpen(!mobilePanelOpen)

	const { t } = useTranslation()

	const title = t(`${pageContext.titleKey}.title`)
	const pathsArray = location.pathname.split('/').filter((p) => p)
	const targetLocation = `/${pathsArray.pop()}`

	if (pageContext.layout) {
		return (
			<div className={classes.root}>
				<CssBaseline />
				<nav className={classes.drawer}>
					<Hidden smUp implementation="js">
						<Navigator
							PaperProps={{ style: { borderWidth: 0, width: drawerWidth } }}
							variant="temporary"
							open={mobileDrawerOpen}
							onClose={onDrawerToggle}
							title={title}
							location={targetLocation}
							iOS={iOS}
						/>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Navigator
							PaperProps={{ style: { borderWidth: 0, width: drawerWidth } }}
							variant="permanent"
							onClose={onDrawerToggle}
							title={title}
							location={targetLocation}
							iOS={iOS}
						/>
					</Hidden>
				</nav>
				<nav>
					<Hidden smUp implementation="js">
						<NotificationsPanel
							PaperProps={{ style: { width: panelWidth } }}
							variant="temporary"
							open={mobilePanelOpen}
							onClose={onPanelToggle}
							title={title}
							location={targetLocation}
							iOS={iOS}
						/>
					</Hidden>
					<Hidden xsDown implementation="css">
						<NotificationsPanel
							PaperProps={{ style: { width: panelWidth } }}
							variant="temporary"
							open={mobilePanelOpen}
							onClose={onPanelToggle}
							title={title}
							location={targetLocation}
							iOS={iOS}
						/>
					</Hidden>
				</nav>
				<div className={classes.appContent}>
					{showAlert ? (
						<Alert
							variant="outlined"
							severity="warning"
							onClose={async () => {
								await storage.setItem('betaUnderstood', false)
								setAlert(false)
							}}
						>
							<AlertTitle>We're under construction</AlertTitle>
							The portal is under heavy development! There are several bugs and incomplete features —{' '}
							<strong>stay tuned!</strong>
						</Alert>
					) : (
						<></>
					)}

					<Header onDrawerToggle={onDrawerToggle} onPanelToggle={onPanelToggle} title={title} />

					<main className={classes.mainContent}>{children}</main>
				</div>
			</div>
		)
	}
	return <main className={classes.mainContent}>{children}</main>
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	children: PropTypes.object,
	pageContext: PropTypes.object,
}
export default withStyles(styles)(Layout)
