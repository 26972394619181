import auth0js from 'auth0-js'
// import { Auth0Client } from '@auth0/auth0-spa-js'
export const isBrowser = typeof window !== 'undefined'

// export const auth0_instance = isBrowser
// 	? new Auth0Client({
// 			domain: process.env.AUTH0_DOMAIN,
// 			client_id: process.env.AUTH0_CLIENT_ID,
// 			// redirect_uri: process.env.AUTH0_CALLBACK,
//       cacheLocation: 'localstorage',
//       useRefreshTokens: true
// 	  })
// 	: {}

export const auth0_auth_instance = isBrowser
	? new auth0js.Authentication({
			domain: process.env.AUTH0_DOMAIN,
			clientID: process.env.AUTH0_CLIENT_ID,
			// redirectUri: process.env.AUTH0_CALLBACK,
			audience: process.env.AUTH0_AUDIENCE,
			responseType: 'token id_token',
			scope: 'openid email profile offline_access roles profile roles https://bigdieta.com/roles',
	  })
	: {}

export const auth0_social_instance = isBrowser
	? new auth0js.WebAuth({
			domain: process.env.AUTH0_DOMAIN,
			clientID: process.env.AUTH0_CLIENT_ID,
			// redirectUri: process.env.AUTH0_CALLBACK,
			audience: process.env.AUTH0_AUDIENCE,
			responseType: 'token id_token',
			scope: 'openid email profile offline_access roles profile roles https://bigdieta.com/roles',
	  })
	: {}
