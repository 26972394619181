import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import * as types from 'action_types'
import { PersistGate } from 'redux-persist/integration/react'
import { configuredStore } from 'src/configureStore'
import { I18nextProvider } from 'react-i18next'
import i18next from 'src/locales/i18next'
import ThemeProvider from './wrap-with-context'
import CalendarProvider from './src/utils/CalendarProvider'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from './src/utils/SnackbarUtils'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'utils/Firebase'

import 'src/styles' // global styles

const rrfProps = {
	firebase,
	dispatch: configuredStore.store.dispatch,
	createFirestoreInstance,
	config: {
		enableClaims: false,
		preserveOnEmptyAuthChange: true,
	},
}

export const wrapRootElement = ({ element }) => {
	return (
		<>
			<Helmet>
				{/* Any head tags go here */}
				<meta http-equiv="Strict-Transport-Security" content="max-age=31536000" />
			</Helmet>
			<Provider store={configuredStore.store}>
				<PersistGate
					loading={null}
					persistor={configuredStore.persistor}
					onBeforeLift={async () => {
						await configuredStore.store.dispatch({ type: 'FAST_FORWARD_STORES' })
					}}
				>
					<ReactReduxFirebaseProvider {...rrfProps}>
						<I18nextProvider i18n={i18next}>
							<CalendarProvider>
								<ThemeProvider>
									<SnackbarProvider maxSnack={3}>
										<>
											<SnackbarUtilsConfigurator />
											{element}
										</>
									</SnackbarProvider>
								</ThemeProvider>
							</CalendarProvider>
						</I18nextProvider>
					</ReactReduxFirebaseProvider>
				</PersistGate>
			</Provider>
		</>
	)
}

export const onRouteUpdate = ({ location, prevLocation }) => {
	configuredStore.store.dispatch({ type: types.SCENE, payload: location.pathname })
}
