import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

function ResponsiveFullscreenDialog({
	open = false,
	title = 'Image Labeler',
	handleClickOpen,
	onClose = () => {},
	orderMap = [],
	data = [],
	currentIndex = 0,
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const handleClose = () => {
		onClose(false)
	}

	// const currentDocument = orderMap.find((item) => {
	//   return item.i === currentIndex
	// })
	// const nextDocument = orderMap.find((item) => {
	//   return item.i === currentIndex + 1
	// })

	// const entry = data.find((item) => {
	//   return item.id === currentDocument.uuid
	// })

	// const nextEntry = data.find((item) => {
	//   return item.id === nextDocument.uuid
	// })

	// console.log(currentDocument);
	// console.log(entry);
	// console.log(nextDocument);
	// console.log(nextEntry);

	return (
		<div>
			<Dialog
				fullScreen={true}
				disableEscapeKeyDown
				open={open}
				TransitionProps={{ onClose: handleClose }}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="contained" autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export { ResponsiveFullscreenDialog }
