import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withStyles, useTheme } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import { Alert, AlertTitle } from '@material-ui/lab'

import CloseIcon from '@material-ui/icons/Close'

import { MessageCard } from '@components'

const styles = (theme) => ({
	mainPanel: {},
	navItemText: {
		paddingLeft: theme.spacing(1),
	},
	listContainer: {},
	actionContainer: {
		paddingTop: 10,
		padding: 2,
	},
	alert: {
		margin: theme.spacing(3),
	},
})

function NotificationsPanel({
	classes,
	PaperProps,
	variant,
	open = true,
	onClose,
	logoutUser,
	auth,
	title,
	location = null,
	iOS = false,
	...rest
}) {
	const theme = useTheme()
	const [value, setValue] = React.useState(0)

	return (
		<SwipeableDrawer
			anchor={'right'}
			open={open}
			onClose={onClose}
			onOpen={() => {}}
			variant={variant}
			PaperProps={PaperProps}
			disableSwipeToOpen={iOS}
		>
			<div className={classes.listContainer}>
				<List disablePadding className={classes.mainPanel}>
					{/* <MessageCard />
					<MessageCard />
					<MessageCard /> */}
					<Alert variant="filled" severity="info" className={classes.alert}>
						<AlertTitle>No notifications</AlertTitle>
						Check back to see important updates here
					</Alert>
				</List>
				{/* <Grid container alignItems="center" spacing={2} direction="row" className={classes.actionContainer}>
					<ListItem dense>
						<Button fullWidth variant="contained" startIcon={<CloseIcon />}>
							<ListItemText
								className={classes.navItemText}
								classes={{
									primary: classes.itemPrimary,
									textDense: classes.textDense,
								}}
							>
								Dismiss All
							</ListItemText>
						</Button>
					</ListItem>
				</Grid> */}
			</div>
		</SwipeableDrawer>
	)
}

NotificationsPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string,
	location: PropTypes.string,
	auth: PropTypes.shape({
		accessToken: PropTypes.string,
		name: PropTypes.string,
		nickname: PropTypes.string,
		roles: PropTypes.array,
	}).isRequired,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}
export default connect(mapStateToProps, actions)(withStyles(styles)(NotificationsPanel))
