import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import RefreshIcon from '@material-ui/icons/Refresh'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import Tooltip from '@material-ui/core/Tooltip'

import moment from 'moment'
import { TimeSeconds } from 'utils/helpers'
import Color from 'color'
import { SymptomScoreChart, SymptomScoresLifetime } from '@components'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Name', format: (value) => value },
	{
		id: 'symptom',
		align: 'center',
		numeric: false,
		disablePadding: false,
		label: 'Symptom',
		format: (value) => value,
	},
	{
		id: 'duration',
		align: 'center',
		numeric: true,
		disablePadding: false,
		label: 'Duration',
		format: (value) => value,
	},
	{ id: 'begun', align: 'center', numeric: false, disablePadding: false, label: 'Begun', format: (value) => value },
	{ id: 'ended', align: 'center', numeric: false, disablePadding: false, label: 'Ended', format: (value) => value },
	{ id: 'effect', align: 'center', numeric: true, disablePadding: false, label: 'Effect', format: (value) => value },
]

function EnhancedTableHead(props) {
	const { classes, order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell />
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const useRowStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	emptyGraph: {
		margin: theme.spacing(2),
		borderWidth: 1,
		borderStyle: 'solid',
		padding: theme.spacing(2),
	},
	emptyGraphContents: {
		padding: 10,
		textAlign: 'center',
	},
}))

const effectIcon = (effect) => {
	if (effect === null) {
		return <></>
	} else if (effect === 50) {
		return (
			<Tooltip title="No effect on symptoms">
				<TrendingFlatIcon color={'disabled'} />
			</Tooltip>
		)
	} else if (effect < 50) {
		return (
			<Tooltip title="Symptoms have gotten worse after experiment">
				<TrendingDownIcon color={'error'} />
			</Tooltip>
		)
	} else {
		return (
			<Tooltip title="Sypmtoms have improved after experiment">
				<TrendingUpIcon color={'primary'} />
			</Tooltip>
		)
	}
}

function Row(props) {
	const { row, symptomScore } = props
	const [open, setOpen] = React.useState(false)
	const classes = useRowStyles()
	const startDate = moment(row.occurred_at).format('YYYY-MM-DD')
	const endDate = moment(row.completed_at).format('YYYY-MM-DD')
	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton color="inherit" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row" align="left">
					{row.meta?.friendlyName || row.name}
				</TableCell>
				<TableCell align="right">{row.symptom}</TableCell>
				<TableCell align="right">{row.duration ? TimeSeconds(row.duration, 1) : 'Self Bounded'}</TableCell>
				<TableCell align="right">{moment(row.occurred_at).format('YYYY-MM-DD')}</TableCell>
				<TableCell align="right">
					{row.completed_at ? moment(row.completed_at).format('YYYY-MM-DD') : 'In Progress'}
				</TableCell>
				<TableCell align="right">{effectIcon(row.effect)}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell
											style={{
												borderStyle: 'none',
											}}
										>
											Details
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableCell
										style={{
											borderStyle: 'none',
										}}
										component="th"
										scope="row"
										align="left"
									>
										{row.recommendation}
									</TableCell>
								</TableBody>
							</Table>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									{startDate === endDate ? (
										<div className={classes.emptyGraph}>
											<Typography variant="body1" color="textSecondary" component="body1">
												Symptom Score Trend:
											</Typography>
											<br />
											<div className={classes.emptyGraphContents}>
												<Typography variant="body2" color="textSecondary" component="body2">
													Unavailable: Immediately Ended after Started :(
												</Typography>
											</div>
										</div>
									) : (
										// <SymptomScoresLifetime
										// 	title={'Symptoms During Experiment'}
										// 	paddingTop={0}
										// 	loading={false}
										// 	bounds={[startDate, endDate]}
										// 	symptomScores={symptomScore.daysScores}
										// />
										<SymptomScoreChart
											scores={symptomScore.daysScores}
											range={{
												start: startDate,
												end: endDate,
											}}
										/>
									)}
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

Row.propTypes = {
	row: PropTypes.shape({
		name: PropTypes.string.isRequired,
		symptom: PropTypes.string.isRequired,
		duration: PropTypes.number,
		occurred_at: PropTypes.string,
		completed_at: PropTypes.string,
		effect: PropTypes.number.isRequired,
		meta: PropTypes.shape({
			theme: PropTypes.string.isRequired,
			friendlyName: PropTypes.string.isRequired,
		}),
	}).isRequired,
}

const useStyles = makeStyles((theme) => ({
	wrapper: {
		margin: theme.spacing(2),
	},
	container: {
		maxHeight: 440,
	},
	titleBar: {
		padding: theme.spacing(1),
	},
	interventionGroupHeader: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
      ${
			theme.palette.mode === 'dark'
				? Color(theme.palette.background.paper).lighten(0.7)
				: Color(theme.palette.background.paper).darken(0.2)
		} 3%,
      ${theme.palette.background.paper} 90%)`,
	},
}))

function InterventionsTable(props) {
	const classes = useStyles()
	const { interventions, symptomScore, loading, onRefresh } = props
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('completed_at')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	return (
		<>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs>
							<Typography variant="h5" color="textSecondary" component="h5">
								Historical Experiments
							</Typography>
						</Grid>
						<Grid item>
							<IconButton color="inherit" onClick={onRefresh}>
								<RefreshIcon className={classes.block} color="inherit" />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			<div className={classes.wrapper}>
				{!loading ? (
					<>
						<TableContainer component={Paper} className={classes.container}>
							<Table stickyHeader aria-label="collapsible table">
								<EnhancedTableHead
									classes={classes}
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
									rowCount={interventions.length}
								/>
								<TableBody>
									{stableSort(interventions, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, i) => {
											return <Row symptomScore={symptomScore} key={row.name} row={row} />
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={interventions.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</>
				) : (
					<Grid container justifyContent="center">
						<CircularProgress />
					</Grid>
				)}
			</div>
		</>
	)
}

export { InterventionsTable }
