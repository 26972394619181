import * as types from 'action_types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getLabelingImage, getLabelingImageOriginal, getLabelingProfile, getLabelingStores } from 'api/labeling'

export const fetchLabelerContext = () => {
  async function thunk(dispatch) {
    const profile = await getLabelingProfile()
    handleSuccess(dispatch, types.SET_LABELING_PROFILE, profile.data)
    if (profile.data) {
      const stores = await getLabelingStores()
      if (stores.data && stores.data.stores)
      handleSuccess(dispatch, types.SET_LABELING_STORES, stores.data.stores)
    }
  }
  return thunk
}

export const setLabelingId = (uuid) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_ENTRY_UID, uuid)
		} catch (err) {}
	}

	return thunk
}

export const setTableWorklist = (args) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_WORKLIST, args)
		} catch (err) {}
	}

	return thunk
}

export const setRenderedTable = (data) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_TABLE_RENDERED, data)
		} catch (err) {}
	}

	return thunk
}

export const setLabelingGuidePreference = (val) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_GUIDE_PREFERENCE, val)
		} catch (err) {}
	}
	return thunk
}

export const setLabelingImageZoomPreference = (val) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_IMAGE_ZOOM_PREFERENCE, val)
		} catch (err) {}
	}
	return thunk
}

export const setLabelingInstructionsVisible = (bool) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_INSTRUCTIONS_VISIBLE, bool)
		} catch (err) {}
	}
	return thunk
}

export const setTableVisibility = (bool) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_VISIBILITY, bool)
		} catch (err) {}
	}
	return thunk
}

export const setVisibleLabelingGuide = (name) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.SET_LABELING_VISIBILE_GUIDE, name)
		} catch (err) {}
	}
	return thunk
}

export const fetchLabelingImage = (uuid, file) => {
	async function thunk(dispatch) {
		try {
			const resp = await getLabelingImage(uuid, file)
			return resp.data.uri
		} catch (err) {}
	}

	return thunk
}

export const setListDb = (db) => {
	async function thunk(dispatch) {
		handleSuccess(dispatch, types.SET_LABELING_DB, db)
	}
	return thunk
}

export const setInterestedCharacteristics = (characteristics) => {
	async function thunk(dispatch) {
		handleSuccess(dispatch, types.SET_INTERESTED_CHARACTERISTICS, characteristics)
	}
	return thunk
}

export const toggleSubmissionLock = (bool) => {
  async function thunk(dispatch) {
    handleSuccess(dispatch, types.TOGGLE_SUBMISSION_LOCK, bool)
  }
  return thunk
}

export const setTeamSize = (num) => {
  async function thunk(dispatch) {
    handleSuccess(dispatch, types.SET_TEAM_SIZE, num)
  }
  return thunk
}

export const setTeamMemberId = (num) => {
  async function thunk(dispatch) {
    handleSuccess(dispatch, types.SET_TEAM_MEMBER_ID, num)
  }
  return thunk
}

export const fetchOriginalImage = (uuid, file) => {
	async function thunk(dispatch) {
		try {
			const resp = await getLabelingImageOriginal(uuid, file)
			return resp.data.uri
		} catch (err) {}
	}

	return thunk
}
