import { applyMiddleware, createStore, compose } from 'redux'
import { createLogger } from 'redux-logger'
import ReduxThunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import { rootReducer, storage } from 'src/reducers'

if (process.env.NODE_ENV === 'production') {
	console.log = () => {}
}
const logger = createLogger({})
let rehydrationComplete
let rehydrationFailed

const rehydrationPromise = new Promise((resolve, reject) => {
	rehydrationComplete = resolve
	rehydrationFailed = reject
})
const rehydration = () => {
	return rehydrationPromise
}

const middlewares = [ReduxThunk]
if (process.env.NODE_ENV !== 'production') {
	middlewares.push(logger)
}
const middlewareEnhancer = applyMiddleware(...middlewares)
const enhancers = [middlewareEnhancer]
const composer =
	(process.env.NODE_ENV !== 'production' &&
		typeof window !== 'undefined' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose
const composedEnhancers = composer(...enhancers)

const configureStore = (preloadedState) => {
	const store = createStore(rootReducer, preloadedState, composedEnhancers)

	if (module.hot) {
		try {
			module.hot.accept('reducers', () => {
				// This fetches the new state of the above reducers.
				const nextPersistReducer = require('reducers')
				store.replaceReducer(persistReducer(nextPersistReducer.persistConfig, nextPersistReducer.rootReducer))
			})
		} catch (err) {}
	}

	const persistor = persistStore(store, null, rehydrationComplete)
	return { store, persistor }
}

const configuredStore = configureStore()

export { configuredStore, rehydration, storage }
