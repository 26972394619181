import axiosInstance from 'src/axiosConfig'
import {
	paginateDefaultArgs,
	paginateExhaust,
	getAddedItemsInArrayByID,
	getRemovedItemsInArrayByID,
} from 'api/api_utils'

export const getLabelingImageOriginal = (uuid, file) => {
	return axiosInstance.request({
		url: `v0/federated/bowels/${uuid}/${file}`,
		method: 'get',
	})
}

export const getLabelingImage = (uuid, file) => {
	return axiosInstance.request({
		url: `v0/federated/cropped/${uuid}/${file}`,
		method: 'get',
	})
}

export const getLabelingStores = () => {
  return axiosInstance.request({
    url: `v1/labeler/access`,
    method: 'get',
  })
}

export const getLabelingProfile = () => {
  return axiosInstance.request({
    url: `v1/labeler`,
    method: 'get',
  })
}
