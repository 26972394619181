import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import moment from 'moment'
import { TimeSeconds } from 'utils/helpers'
import Color from 'color'

const QUESTIONS = [
	{ q: 'Have these dietary changes helped any of your symptoms?', category: 'Dietary Change', priority: 2 },
	{ q: 'Have these diets helped any of your symptoms?', category: 'Diet', priority: 1 },
	{ q: 'Have these medication changes helped any of your symptoms?', category: 'Medication', priority: 4 },
	{ q: 'Have these lifestyle changes helped any of your symptoms?', category: 'Lifestyle', priority: 0 },
	{ q: 'Have these antibiotics helped any of your symptoms?', category: 'Antibiotic', priority: 3 },
]

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Name', format: (value) => value },
	{
		id: 'category',
		align: 'left',
		numeric: false,
		disablePadding: false,
		label: 'Type',
		format: (value) => value,
	},
	{ id: 'helped', align: 'left', numeric: false, disablePadding: false, label: 'Helped', format: (value) => value },
]

function EnhancedTableHead(props) {
	const { classes, order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
})

const effectIcon = (effect) => {
	if (effect === null) {
		return <></>
	} else if (effect === 50) {
		return <TrendingFlatIcon color={'disabled'} />
	} else if (effect < 50) {
		return <TrendingDownIcon color={'error'} />
	} else {
		return <TrendingUpIcon color={'primary'} />
	}
}

function Row(props) {
	const { row } = props
	const [open, setOpen] = React.useState(false)
	const classes = useRowStyles()

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell align="left">{row.name}</TableCell>
				<TableCell align="left">{row.category}</TableCell>
				<TableCell align="left">
					<span
						style={{
							padding: 5,
							borderRadius: 5,
							backgroundColor: row.helped === 'no' ? 'red' : 'green',
							color: 'white',
						}}
					>
						{row.helped}
					</span>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

Row.propTypes = {
	row: PropTypes.shape({
		name: PropTypes.string.isRequired,
		category: PropTypes.string.isRequired,
		helped: PropTypes.string.isRequired,
	}).isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(1),
		maxHeight: 380,
	},
	interventionGroupHeader: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
      ${
			theme.palette.mode === 'dark'
				? Color(theme.palette.background.paper).lighten(0.7)
				: Color(theme.palette.background.paper).darken(0.2)
		} 3%,
      ${theme.palette.background.paper} 90%)`,
	},
}))

function InterventionsTriedTable(props) {
	const classes = useStyles()
	const { question } = props

	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('priority')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const generalGI = question.data
		.filter((question) => {
			return question.issuer === 'General GI Survey'
		})
		.reduce((acc, ans) => {
			if (ans.answer && ans.answer[0] && typeof ans.answer[0] === 'object') {
				const relevantAnswers = Object.keys(ans.answer[0]).reduce((acc2, answer) => {
					const resp = ans.answer[0][answer]
					if (resp && resp.toLowerCase().includes('tried')) {
						const idx = QUESTIONS.find((ques) => ques.q === ans.question)

						acc2 = {
							...acc2,
							[answer]: {
								category: idx.category,
								priority: idx.priority,
								helped: resp.includes("didn't") ? 'no' : 'yes',
							},
						}
					}
					return acc2
				}, {})
				acc = {
					...acc,
					...relevantAnswers,
				}
			}
			return acc
		}, {})

	const rows = Object.keys(generalGI).map((item) => {
		return {
			name: item,
			category: generalGI[item].category,
			priority: generalGI[item].priority,
			helped: generalGI[item].helped,
		}
	})

	const isLoading = question.loading.read

	return !isLoading ? (
		question.data.length ? (
			<TableContainer component={Paper} className={classes.root}>
				<Table stickyHeader aria-label="collapsible table">
					<EnhancedTableHead
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={Object.keys(generalGI).length}
					/>
					<TableBody>
						{stableSort(rows, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, i) => {
								return <Row key={i} row={row} />
							})}
					</TableBody>
				</Table>
			</TableContainer>
		) : (
			<>Survey results not yet available.</>
		)
	) : (
		<Grid container justifyContent="center">
			<CircularProgress />
		</Grid>
	)
}

export { InterventionsTriedTable }
