import React, { useState, useCallback, useEffect } from 'react'
import moment from 'moment'
import humanizeDuration from 'humanize-duration'
import { uuid } from 'uuidv4'

const ROUND_TO_NTH_MINUTE = 5

export const debounce = (delay, fn) => {
	let timeout

	return function (...args) {
		if (timeout) {
			clearTimeout(timeout)
		}

		timeout = setTimeout(() => {
			fn(...args)
			timeout = null
		}, delay)
	}
}

export const associateRequest = (data, type) => {
	if (!data) {
		data = {}
	}
	if (!data._app) {
		data._app = {}
	}
	if (!data._app.uuid) {
		data._app.uuid = uuid()
	}
	if (type) {
		data._app.type = type
	}
	delete data._app.err // remove any prior errors
	return data
}

export const getTimeAtCurrentDay = (date, hours, minutes = 0) => {
	const specifiedTime = moment(date).startOf('day').add(hours, 'hours').add(minutes, 'minutes')

	return specifiedTime
}

export const TimeSeconds = (seconds, largest = 2) => {
	return humanizeDuration(seconds * 1000, { largest, round: true })
}

export const getRange = (startDate, endDate, type) => {
	let fromDate = moment(startDate)
	let toDate = moment(endDate)
	let diff = toDate.diff(fromDate, type)
	let range = []
	for (let i = 0; i < diff; i++) {
		range.push(moment(startDate).add(i, type))
	}
	return range
}

export const EnumerateDaysBetweenDates = function (startDate, endDate) {
	const now = startDate.clone(),
		dates = []
	while (now.isSameOrBefore(endDate)) {
		dates.push(now.format('YYYY-MM-DD'))
		now.add(1, 'days')
	}
	return dates
}

export const getDefaultOccurredAt = (attentionDate) => {
	const start = moment(attentionDate)
	const remainder = ROUND_TO_NTH_MINUTE - (start.minute() % ROUND_TO_NTH_MINUTE)
	let occurred_at = moment(start)

	if (remainder <= 2) {
		occurred_at = occurred_at.add(remainder, 'minutes')
	} else {
		occurred_at = occurred_at.subtract(start.minute() % ROUND_TO_NTH_MINUTE, 'minutes')
	}
}

export const useDebounce = (value, timeout) => {
  const [state, setState] = useState(value);

  useEffect(() => {
      const handler = setTimeout(() => setState(value), timeout);

      return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
}

export const useDebouncedEffect = (effect, delay, deps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
      const handler = setTimeout(() => {
          callback();
      }, delay);

      return () => {
          clearTimeout(handler);
      };
  }, [callback, delay]);
}

