const path = require('path')

const routes = {
	404: {
		path: '/404',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, '404.js'),
	},
	analytics: {
		path: '/analytics',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'analytics.js'),
	},
	authentication: {
		path: '/authentication',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'authentication.js'),
	},
	callback: {
		path: '/callback',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'callback.js'),
	},
	forgotPassword: {
		path: '/forgot-password',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'forgotPassword.js'),
	},
	functions: {
		path: '/functions',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'functions.js'),
	},
	home: {
		path: '/',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'home.js'),
	},
	hosting: {
		path: '/hosting',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'hosting.js'),
	},
	interventions: {
		path: '/interventions',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'interventions.js'),
	},
	legacy: {
		path: '/legacy',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'legacy.js'),
	},
	adminLogin: {
		path: '/login/admin',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'adminLogin.js'),
	},
	login: {
		path: '/login',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'login.js'),
	},
	ml: {
		path: '/ml',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'ml.js'),
	},
	list: {
		path: '/list',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'list.js'),
	},
	profile: {
		path: '/profile',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'profile.js'),
	},
	settings: {
		path: '/settings',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'settings.js'),
	},
	signup: {
		path: '/signup',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'signup.js'),
	},
}

// Same keys as 'routes', but the value is only the path.
const paths = Object.keys(routes).reduce((acc, route) => {
	acc[route] = routes[route].path
	return acc
}, {})

const pathRouteMap = Object.keys(routes).reduce((acc, route) => {
	const config = routes[route]
	acc[config.path] = {
		...config,
		name: route,
	}
	return acc
}, {})

module.exports = { routes, paths, pathRouteMap }
