import React from 'react'
import MuiLink from '@material-ui/core/Link'
import { Link as GLink } from 'gatsby'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'

const useStyles = (props) =>
	makeStyles((theme) => ({
		link: {
			fontWeight: 'bold',
			color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
		},
	}))

const Link = (props) => {
	const classes = useStyles(props)()
	const theme = useTheme()
	if (props.href) {
		return <MuiLink {...props} className={classes.link} />
	}
	return <GLink {...props} className={classes.link} />
}
export { Link }
