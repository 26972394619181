import * as types from 'action_types'

const INITIAL_STATE = {
	isConnected: true,
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		case types.NETWORK_CONNECTION_CHANGE:
			return {
				...state,
				isConnected: action.payload.isConnected,
			}

		default:
			return state
	}
}
