import * as storage from 'localforage' // defaults to localStorage for web
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import AuthReducer from 'reducers/AuthReducer'
import NetworkReducer from 'reducers/NetworkReducer'
import DeviceReducer from 'reducers/DeviceReducer'
import LabelingReducer from 'reducers/LabelingReducer';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const persistedDeviceReducers = persistReducer(
	{
		key: 'device',
		storage,
		timeout: 0,
	},
	DeviceReducer
)

const combinedReducers = combineReducers({
	device: persistedDeviceReducers,
	auth: AuthReducer,
  network: NetworkReducer,
  // firebase: firebaseReducer,
  firebase: persistReducer(
    { key: 'firebase', storage, stateReconciler: hardSet },
    firebaseReducer
  ),
  firestore: persistReducer(
    { key: 'firestore', storage, stateReconciler: hardSet },
    firestoreReducer
  ),
  labeling: LabelingReducer
})

const persistConfig = {
	key: 'primary',
	storage,
	blacklist: ["labeling"],
	transforms: [],
	timeout: 0,
}

const persistedCombinedReducer = persistReducer(persistConfig, combinedReducers)

const rootReducer = (state, action) => {
	return persistedCombinedReducer(state, action)
}

export { rootReducer, persistConfig, combinedReducers, storage }
