import React, { useCallback, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import TagFacesIcon from '@material-ui/icons/TagFaces'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'
import Input from '@material-ui/core/Input'
import { faAssistiveListeningSystems } from '@fortawesome/free-solid-svg-icons'
import _debounce from 'lodash.debounce'

const rendered = {}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	pillGroup: {
		display: 'flex',
		justifyContent: 'center',
	},
	input: {
		width: 20,

		'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
	labelValue: {
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
	},
}))

const LabelChipListEditable = (props) => {
	const classes = useStyles()
	const chipData = props.data
  const suggestions = props.suggestions;
	const firstRef = useRef(null)
	const [dataMap, setDataMap] = useState(
		chipData.reduce((acc, item) => {
			acc[item.name] = item.value
			return acc
		}, {})
	)

	const interestedCharacteristics = props.interestedCharacteristics
	const interests = interestedCharacteristics.map((i) => i.name)

	const debounceSave = useCallback(
		_debounce((uid, changes, labelModified) => {
			props.onChange(changes)
			props.onSave(uid, changes, labelModified)
		}, 1000),
		[]
	)

	let dataSet = Object.keys(dataMap).length
		? Object.keys(dataMap).map((key, index) => {
				return {
					// key: index,
					name: key,
					value: dataMap[key],
				}
		  })
		: interestedCharacteristics
	if (!dataSet.length) {
		dataSet = Array.from(interestedCharacteristics)
	} else {
		dataSet = interestedCharacteristics
			.reduce((acc, item) => {
				if (!dataSet.find((feature) => feature.name === item.name)) {
					acc.push(item)
				}
				return acc
			}, dataSet)
			.sort((a, b) => (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase() ? -1 : 1))
			.map((item, i) => {
				return {
					...item,
					key: i,
				}
			})
	}
	const hiddenCharacteristics = dataSet
		.map((i) => i.name)
		.reduce((acc, item) => {
			if (!interests.includes(item)) {
				acc.push(item)
			}
			return acc
		}, [])

  const totalSuggestions = suggestions.length;

	// if (!rendered[])

	// firstRef && firstRef.current && firstRef.current.focus()

	let firstUnmarkedKey = dataSet.findIndex((item) => {
		return !Number.isInteger(item.value)
	})

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
			}}
			noValidate
			autoComplete="off"
		>
			<Paper component="ul" className={classes.root}>
				{dataSet.map((data) => {
					let icon

					if (hiddenCharacteristics.includes(data.name)) {
						return <></>
					}

					return (
						<li key={data.key}>
							<div className={classes.pillGroup}>
								<Chip
									icon={icon}
									label={data.name}
									onDelete={props.handleDelete && props.handleDelete(data)}
									className={classes.chip}
								/>
								<Divider orientation={'vertical'} />
								<Input
									type={'number'}
									className={classes.input}
									autoFocus={firstUnmarkedKey === data.key}
									value={data.value}
									inputProps={{ 'aria-label': 'label value' }}
									onFocus={() => {
										props.setGuide(data.name)
									}}
									onChange={(val) => {
										const changes = {
											...dataMap,
											[data.name]: val.target.value ? Number(val.target.value) : null,
										}
										setDataMap(changes)
										debounceSave(props.uid, changes, data.name)
									}}
								/>
							</div>
						</li>
					)
				})}
			</Paper>
			{/* <ul className={classes.root}>
				<li key={0}>
					<div className={classes.pillGroup}>
						<Chip
							onClick={() => handleClick()}
							icon={<Add />}
							label={'Create New'}
							className={classes.chip}
						/>
					</div>
				</li>
			</ul> */}
		</form>
	)
}

export { LabelChipListEditable }
