import React, { useCallback, useEffect, useState, useRef } from 'react'
import { makeStyles, lighten } from '@material-ui/core/styles'
import classNames from 'classnames'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import TagFacesIcon from '@material-ui/icons/TagFaces'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { faAssistiveListeningSystems } from '@fortawesome/free-solid-svg-icons'
import _debounce from 'lodash.debounce'
import { useHotkeys } from 'react-hotkeys-hook'
const rendered = {}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},

	hideLastBorder: {
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	},
  table: {
    position: "relative"
  },
	outline: {
		borderWidth: 1,
		borderStyle: 'solid',
	},
	highlight:
		theme.palette.mode === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
}))

const LabelEfficientPrompt = (props) => {
	const classes = useStyles()
	const data = props.data
  const suggestions = props.suggestions;
  // const suggestions = []
  const isAdmin = props.isAdmin;
	const interestedCharacteristics = props.interestedCharacteristics
	const characteristic = interestedCharacteristics[0]

	const [dataMap, setDataMap] = useState(
		data.reduce((acc, item) => {
			acc[item.name] = item.value
			return acc
		}, {})
	)
	const options = Object.keys(characteristic.options)
	useHotkeys(
		'0',
		() => {
			if (options.includes('0')) {
				onSelect(props.uid, characteristic.name, characteristic.options['0'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'1',
		() => {
			if (options.includes('1')) {
				onSelect(props.uid, characteristic.name, characteristic.options['1'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'2',
		() => {
			if (options.includes('2')) {
				onSelect(props.uid, characteristic.name, characteristic.options['2'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'3',
		() => {
			if (options.includes('3')) {
				onSelect(props.uid, characteristic.name, characteristic.options['3'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'4',
		() => {
			if (options.includes('4')) {
				onSelect(props.uid, characteristic.name, characteristic.options['4'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'5',
		() => {
			if (options.includes('5')) {
				onSelect(props.uid, characteristic.name, characteristic.options['5'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'6',
		() => {
			if (options.includes('6')) {
				onSelect(props.uid, characteristic.name, characteristic.options['6'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'7',
		() => {
			if (options.includes('7')) {
				onSelect(props.uid, characteristic.name, characteristic.options['7'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'8',
		() => {
			if (options.includes('8')) {
				onSelect(props.uid, characteristic.name, characteristic.options['8'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'9',
		() => {
			if (options.includes('9')) {
				onSelect(props.uid, characteristic.name, characteristic.options['9'])
			}
		},
		[characteristic.name]
	)
	useHotkeys(
		'-',
		() => {
			if (options.includes('-')) {
				onSelect(props.uid, characteristic.name, characteristic.options['-'])
			}
		},
		[characteristic.name]
	)

	const debounceSave = useCallback((uid, changes) => {
		props.onChange(changes)
		props.onSave(uid, changes, characteristic.name)
	}, [])

	useEffect(() => {
		props.setGuide(characteristic.name)
	}, [])

	const onSelect = useCallback((uid, name, value) => {
		const changes = {
			...dataMap,
			[name]: value,
		}
		setDataMap(changes)
		debounceSave(uid, changes)
	})

  const totalSuggestions = suggestions.length || 1
	const selectedValue = dataMap[characteristic.name]
	const rows = Object.keys(characteristic.options).map((option, index) => {
		return {
			id: index,
			name: characteristic.name,
			value: characteristic.options[option],
			shortcut: option,
			selected: characteristic.options[option] === selectedValue,
		}
	})

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="label shortcuts">
				<TableHead>
					<TableRow>
						<TableCell>Class</TableCell>
						<TableCell>Value</TableCell>
						<TableCell align="right">Keyboard</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, i) => (
            <>
              <div style={{
                position: 'absolute',
                pointerEvents: 'none',
                backgroundColor: row.selected ? 'green' : 'black',
                opacity: isAdmin ? .3 : 0,
                width: `${(suggestions.filter(d => d === row.value).length / totalSuggestions) * 100}%`,
                height: 41
              }} />
              <TableRow
                className={classNames([classes.hideLastBorder, { [classes.highlight]: row.selected }])}
                tabIndex={-1}
                hover
                key={i}
                selected={row.selected}
                onClick={(event) => onSelect(props.uid, row.name, row.value)}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right" scope="row">
                  {row.value}
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle1">{row.shortcut}</Typography>
                </TableCell>
                
              </TableRow>
             
            </>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export { LabelEfficientPrompt }
