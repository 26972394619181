import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import { PieRadialChart, SafeWorstBarChart } from '@components'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'trigger', numeric: false, disablePadding: false, label: 'Trigger' },
	{ id: 'lag', numeric: false, disablePadding: false, label: 'Lag' },
	{ id: 'impact', numeric: true, disablePadding: false, label: 'Impact' },
]

function EnhancedTableHead(props) {
	const { classes, order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'center'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.mode === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
	title: {
		flex: '1 1 100%',
	},
	titleContainer: {
		paddingTop: theme.spacing(1),
	},
}))

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles()
	const [open, setOpen] = React.useState(false)

	const handleTooltipClose = () => {
		setOpen(false)
	}

	const handleTooltipOpen = () => {
		setOpen(true)
	}

	return (
		<Toolbar className={classNames(classes.root)}>
			<Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.titleContainer}>
				<Grid item>
					<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
						{props.title}
					</Typography>
				</Grid>
				<Grid item>
					<ClickAwayListener onClickAway={handleTooltipClose}>
						<div>
							<Tooltip
								PopperProps={{
									disablePortal: true,
								}}
								onClose={handleTooltipClose}
								open={open}
								disableFocusListener
								disableHoverListener
								disableTouchListener
								title={props.tooltip}
							>
								<HelpOutlineIcon onClick={handleTooltipOpen} />
							</Tooltip>
						</div>
					</ClickAwayListener>
				</Grid>
			</Grid>
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	tooltip: PropTypes.string,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		overflow: 'hidden',
		// marginBottom: theme.spacing(2),
	},
	table: {
		// minWidth: 500,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}))

function SafeWorstTable(props) {
	const classes = useStyles()
	const [order, setOrder] = React.useState(props.defaultOrder || 'asc')
	const [orderBy, setOrderBy] = React.useState('impact')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(5)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.triggers.length - page * rowsPerPage)

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar title={props.title} tooltip={props.tooltip} />
				<SafeWorstBarChart
					title={props.title}
					triggers={props.triggers}
					defaultOrder={props.defaultOrder}
					colorRange={props.colorRange}
				/>
				{/* <PieRadialChart title={props.title} triggers={props.triggers} /> */}
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={'medium'}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={props.triggers.length}
						/>
						<TableBody>
							{stableSort(props.triggers, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow tabIndex={-1} key={`${row.trigger}-${index}`}>
											<TableCell align="center">{row.trigger}</TableCell>
											<TableCell align="center">{row.lag}</TableCell>
											<TableCell align="center">{row.impact}</TableCell>
										</TableRow>
									)
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={props.triggers.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

export { SafeWorstTable }
