import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
	card: {
		width: '100%',
		// maxWidth: 500,
		borderRadius: spacing(2), // 16px
		transition: '0.3s',
		boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
		position: 'relative',
		overflow: 'initial',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		paddingLeft: 8,
		paddingRight: 8,
		background: 'linear-gradient(34deg, rgba(55,16,83,1) 0%, rgba(162,73,190,1) 29%, rgba(33,16,83,1) 92%)',
		[breakpoints.up('sm')]: {
			textAlign: 'left',
			flexDirection: 'row-reverse',
		},
	},
	mediaContainer: {
		padding: 10,
	},
	media: {
		flexShrink: 0,
		transform: `scale(.7)`,
		width: '25%',
		paddingTop: '25%',
		marginLeft: 'auto',
		marginRight: 'auto',
		[breakpoints.up('sm')]: {
			marginRight: 'initial',
		},
		[breakpoints.down('xs')]: {
			marginTop: spacing(4),
		},
	},
	overline: {
		lineHeight: 2,
		color: '#ffffff',
		fontWeight: 'bold',
		fontSize: '0.625rem',
		opacity: 0.7,
	},
	heading: {
		fontWeight: '900',
		color: '#ffffff',
		letterSpacing: 0.5,
	},
	button: {
		display: 'block',
		marginTop: 10,
		color: palette.common.white,
	},
}))

const roleMedias = {
	Admin: '/svgs/card/admin_logo.svg',
	Dietitian: '/svgs/card/dietitian_logo.svg',
	Gastroenterologist: '/svgs/card/gastroenterologist_logo.svg',
}

const RoleCard = ({ clinician, title, bgSrc = 'https://jkkm.info/ui/images/awards/victory.png', toggleVisibility }) => {
	const { t } = useTranslation()
	const classes = useStyles()
	if (clinician.title) {
		bgSrc = roleMedias[clinician.title]
	}

	const handleChange = (event) => {
		toggleVisibility({ key: clinician.key, is_enrolling: event.target.checked })
	}

	return (
		<Card className={classes.card}>
			{/* <div className={classes.mediaContainer}>
				
			</div> */}
			<CardMedia className={classes.media} image={bgSrc} />
			<CardContent className={classes.content}>
				<Typography className={classes.heading} variant={'h6'}>
					{title}
				</Typography>
				<Typography className={classes.overline} variant={'overline'} gutterBottom>
					{clinician.title}
				</Typography>
				{/* <Button className={classes.button}>View Winners</Button> */}
				<FormControlLabel
					className={classes.button}
					control={
						<Switch
							checked={!!clinician.is_enrolling}
							color="primary"
							onChange={handleChange}
							name="enrolling"
						/>
					}
					label={
						clinician.is_enrolling
							? `${t('components.role_card.enrolling')}`
							: `${t('components.role_card.not_enrolling')}`
					}
				/>
			</CardContent>
		</Card>
	)
}

export { RoleCard }
