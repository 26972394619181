import React, { useMemo } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { deepPurple, pink, red } from '@material-ui/core/colors'
import useDarkMode from 'use-dark-mode'
import Color from 'color'

const PRIMARY = {
	50: '#f2f0fe',
	100: '#dddafc',
	200: '#c7c1fa',
	300: '#b1a8f7',
	400: '#a095f6',
	500: '#8f82f4',
	600: '#877af3',
	700: '#7c6ff1',
	800: '#7265ef',
	900: '#6052ec',
	A100: '#ffffff',
	A200: '#ffffff',
	A400: '#e7e5ff',
	A700: '#cfcbff',
	contrastDefaultColor: 'dark',
}

const SECONDARY = {
	50: '#e3e5e8',
	100: '#b9bdc5',
	200: '#8b919e',
	300: '#5c6577',
	400: '#394459',
	500: '#16233c',
	600: '#131f36',
	700: '#101a2e',
	800: '#0c1527',
	900: '#060c1a',
	A100: '#597eff',
	A200: '#2656ff',
	A400: '#0035f2',
	A700: '#0030d9',
	contrastDefaultColor: 'light',
}
function Theme({ path, children }) {
	const darkMode = useDarkMode()
	const theme = React.useMemo(() => {
		const defaultTheme = createMuiTheme()
		return createMuiTheme({
			...defaultTheme,
			palette: {
				mode: darkMode.value ? 'dark' : 'light',
				primary: PRIMARY,
				secondary: SECONDARY,
				error: red,
			},
			typography: {
				useNextVariants: true,
				h5: {
					fontWeight: 500,
					fontSize: 26,
					letterSpacing: 0.5,
				},
			},
			shape: {
				borderRadius: 8,
			},
			components: {
				MuiTab: {
					defaultProps: {
						disableRipple: true,
					},
					styleOverrides: {
						root: {
							textTransform: 'initial',
							margin: '0 16px',
							minWidth: 0,
							[defaultTheme.breakpoints.up('md')]: {
								minWidth: 0,
							},
						},
						labelContainer: {
							padding: 0,
							[defaultTheme.breakpoints.up('md')]: {
								padding: 0,
							},
						},
					},
				},
				MuiDrawer: {
					styleOverrides: {
						paper: {
							backgroundColor: SECONDARY[500],
						},
					},
				},
				MuiButton: {
					styleOverrides: {
						label: {
							textTransform: 'initial',
						},
						contained: {
							boxShadow: 'none',
							'&:active': {
								boxShadow: 'none',
							},
						},
					},
				},
				MuiTabs: {
					styleOverrides: {
						root: {
							marginLeft: defaultTheme.spacing(1),
						},
						indicator: {
							height: 3,
							borderTopLeftRadius: 3,
							borderTopRightRadius: 3,
							backgroundColor: defaultTheme.palette.common.white,
						},
					},
				},
				MuiTableHeader: {
					styleOverrides: {
						root: {
							backgroundColor: darkMode.value ? '#212121' : '#f5f5f5',
						},
					},
				},
				MuiTableCell: {
					styleOverrides: {
						stickyHeader: {
							'&:first-child': {
								borderTopLeftRadius: defaultTheme.spacing(1),
							},
							'&:last-child': {
								borderTopRightRadius: defaultTheme.spacing(1),
							},
							backgroundColor: darkMode.value ? '#212121' : '#f5f5f5',
						},
					},
				},
				MuiToolbar: {
					styleOverrides: {
						regular: {
							minHeight: 46,
						},
					},
				},
				MuiPickersToolbar: {
					styleOverrides: {
						toolbar: {
							backgroundColor: darkMode.value ? '#212121' : '#f5f5f5',
							color: darkMode.value
								? defaultTheme.palette.common.white
								: defaultTheme.palette.common.black,
						},
					},
				},
				MuiPickersToolbarText: {
					styleOverrides: {
						toolbarTxt: {
							color: darkMode.value ? '#b5b5b5' : Color('#212121').lighten(3).hex(),
						},
						toolbarBtnSelected: {
							color: darkMode.value ? Color('#d5d5d5').lighten(3).hex() : '#212121',
						},
					},
				},
				MuiIconButton: {
					styleOverrides: {
						root: {
							padding: defaultTheme.spacing(1),
						},
					},
				},
				MuiTooltip: {
					styleOverrides: {
						tooltip: {
							borderRadius: 4,
						},
					},
				},
				MuiLink: {
					styleOverrides: {
						root: {
							'&&:visited': {
								color: 'inherit',
							},
						},
					},
				},
				MuiDivider: {
					styleOverrides: {
						root: {
							backgroundColor: '#404854',
						},
					},
				},
				MuiListItemText: {
					styleOverrides: {
						primary: {
							fontWeight: defaultTheme.typography.fontWeightMedium,
						},
					},
				},
				MuiListItemIcon: {
					styleOverrides: {
						root: {
							color: 'inherit',
							marginRight: 0,
							'& svg': {
								fontSize: 20,
							},
						},
					},
				},
				MuiBottomNavigationAction: {
					styleOverrides: {
						root: {
							minWidth: 70,
						},
					},
				},
				MuiAvatar: {
					styleOverrides: {
						root: {
							width: 32,
							height: 32,
						},
					},
				},
			},
			mixins: {
				...defaultTheme.mixins,
				toolbar: {
					minHeight: 48,
				},
			},
		})
	}, [darkMode.value])
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
