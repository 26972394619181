import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import ReplyIcon from '@material-ui/icons/Reply'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 10,
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		backgroundColor: red[500],
	},
}))

function MessageCard() {
	const classes = useStyles()
	const [expanded, setExpanded] = React.useState(false)

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	return (
		<Card className={classes.root}>
			<CardHeader
				avatar={
					<Avatar aria-label="recipe" className={classes.avatar}>
						B
					</Avatar>
				}
				action={
					<IconButton color="inherit" aria-label="settings">
						<MoreVertIcon />
					</IconButton>
				}
				title={'Patient A'}
				subheader="May 20, 2020"
			/>
			<CardActions disableSpacing>
				<IconButton color="inherit" aria-label="reply">
					<ReplyIcon />
				</IconButton>
				<IconButton color="inherit" aria-label="dismiss">
					<CloseIcon />
				</IconButton>
				<IconButton
					className={classNames(classes.expand, {
						[classes.expandOpen]: expanded,
					})}
					color="inherit"
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</IconButton>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						Hey, I'm about finishing my diet. When can we sync on next steps?
					</Typography>
				</CardContent>
			</Collapse>
		</Card>
	)
}

export { MessageCard }
