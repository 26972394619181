export const API = {
	PAGESIZE: 300,
	PAGESLIMIT: 10,
	PAGESLIMIT_MINOR: 2,
}

export const ENTITY_THEME = {
	MEAL: '#b8e9c0',
	MEDICATION: '#9adddd',
	SYMPTOM: '#f3b786',
	BOWEL: '#dd99ab',
	EXERCISE: '#e8f5fb',
	SLEEP: '#ccdbfe',
	MOOD: '#f8ec9f',
	OTHER: '#e5e5ea',
}

export const DIARY_ENTITY_TYPES = ['bowel', 'exercise', 'meal', 'medication', 'symptom', 'sleep', 'other', 'mood']
