import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ContentGraphic from './ContentGraphic'

const styles = (theme) => ({
	paper: {
		margin: 'auto',
		overflow: 'hidden',

		[theme.breakpoints.up('sm')]: {
			minWidth: 600,
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: 936,
		},
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	block: {
		display: 'block',
	},
	addUser: {
		marginRight: theme.spacing(1),
	},
	contentWrapper: {
		height: 368,
	},
	container: {
		padding: '48px 36px 48px',
	},
})

function Content({ classes, title, subtitle, body }) {
	return (
		<div className={classes.container}>
			<Paper className={classes.paper}>
				<Grid
					container
					spacing={2}
					className={classes.contentWrapper}
					wrap={'wrap'}
					alignItems="center"
					justifyContent="center"
				>
					<Grid lg={4} xs={12} item align="center">
						<ContentGraphic />
					</Grid>
					<Grid lg={8} xs={12} item>
						<Typography component="h2" variant="h5" color="textSecondary" align="center">
							{title}
						</Typography>
						<Typography component="h5" variant="body1" color="textSecondary" align="center">
							{subtitle}
						</Typography>
						{body ? <>{body}</> : <></>}
					</Grid>
				</Grid>
			</Paper>
		</div>
	)
}

Content.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
}

export default withStyles(styles)(Content)
