import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withStyles, useTheme } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { Link } from 'gatsby'
import Divider from '@material-ui/core/Divider'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import HomeIcon from '@material-ui/icons/Home'
import HttpsIcon from '@material-ui/icons/Https'
import PeopleIcon from '@material-ui/icons/People'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual'
import PublicIcon from '@material-ui/icons/Public'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import TimerIcon from '@material-ui/icons/Timer'
import Typography from '@material-ui/core/Typography'
import SettingsIcon from '@material-ui/icons/Settings'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import BarChartIcon from '@material-ui/icons/BarChart'
import ForumIcon from '@material-ui/icons/Forum'
import Scrollbar from 'react-scrollbars-custom'

import { Button, ActiveBadgeAvatar, PictureInPictureAvatar, Logo, ResponsiveFullscreenDialog } from '@components'
import { paths } from 'routes'

const categories = [
	{
		id: 'Summary',
		children: [
			// { id: 'Authentication', icon: <PeopleIcon />, page: paths.authentication },
			{
				id: 'Annotate Images',
				icon: <ForumIcon />,
				page: paths.list,
				visibility: { individual: true, clinical: true },
			},
			// { id: 'Hosting', icon: <PublicIcon />, page: paths.hosting },
			// { id: 'Functions', icon: <SettingsEthernetIcon />, page: paths.functions },
			// { id: 'ML Kit', icon: <SettingsInputComponentIcon />, page: paths.ml },
		],
		visibility: { individual: true, clinical: true },
	},
	// {
	// 	id: 'Treatment',
	// 	children: [
	// 		{
	// 			id: 'Analytics',
	// 			icon: <BarChartIcon />,
	// 			page: paths.analytics,
	// 			visibility: { individual: true, clinical: true },
	// 		},
	// 		{
	// 			id: 'More Analysis',
	// 			icon: <PhonelinkSetupIcon />,
	// 			page: paths.legacy,
	// 			visibility: { individual: true, clinical: true },
	// 		},
	// 	],
	// 	visibility: { individual: true, clinical: true },
	// },
	{
		id: 'Account',
		children: [
			// {
			// 	id: 'Profile',
			// 	icon: <AccountBoxIcon />,
			// 	page: paths.profile,
			// 	visibility: { individual: true, clinical: false },
			// },
			{
				id: 'Settings',
				icon: <SettingsIcon />,
				page: paths.settings,
				visibility: { individual: true, clinical: false },
			},
		],
		visibility: { individual: true, clinical: false },
	},
]

const styles = (theme) => ({
	categoryHeader: {
		paddingTop: 16,
		paddingBottom: 16,
	},
	categoryHeaderPrimary: {
		color: theme.palette.common.white,
	},
	navButton: {
		width: '100%',
		background: 'transparent',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
		'&.active:hover': {
			backgroundColor: theme.palette.primary[400],
		},
	},
	navItemText: {
		paddingLeft: theme.spacing(1),
	},
	iconWrapper: {
		margin: 'auto',
		lineHeight: 1,
	},
	item: {
		paddingTop: 4,
		paddingBottom: 4,
		color: 'rgba(255, 255, 255, 0.7)',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	itemCategory: {
		backgroundColor: '#232f3e',
		boxShadow: '0 -1px 0 #404854 inset',
		paddingTop: 16,
		paddingBottom: 16,
	},
	itemPersonal: {
		boxShadow: '0 -1px 0 #404854 inset',
		paddingTop: 26,
		paddingBottom: 13,
	},
	firebase: {
		fontSize: 24,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.common.white,
	},
	topNameMargin: {
		marginTop: 15,
	},
	itemActionable: {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	itemActiveItem: {
		backgroundColor: theme.palette.primary[500],
	},
	itemPrimary: {
		color: 'inherit',
		fontSize: theme.typography.fontSize,
		'&$textDense': {
			fontSize: theme.typography.fontSize,
		},
	},
	divider: {
		marginTop: theme.spacing(2),
	},
	noSelect: {
		userSelect: 'none',
	},
	center: {
		textAlign: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
	flexContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	flexHeader: {
		flexGrow: 0,
		flexShrink: 1,
		flexBasis: 'auto',
	},
	flexList: {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 'auto',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			backgroundColor: theme.palette.secondary[500],
			width: 8,
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#8a9bb2',
			borderRadius: 8,
			border: '2px solid #262f3d',
		},
	},
	flexFooter: {
		flexGrow: 0,
		flexShrink: 1,
		flexBasis: 'auto',
	},
  personaSection: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  }
})

function Navigator({
	classes,
	PaperProps,
	variant,
	open = true,
	onClose,
	logoutUser,
	getUserPicture,
	auth,
	title,
	location = null,
	iOS = false,
	...rest
}) {
	const theme = useTheme()
	const [value, setValue] = React.useState(0)
	const [locked, unlock] = React.useState(false)
	const isMonitoring = false
  const safeAuthName = (auth.name || auth.nickname).split('@')[0]
	return (
		<SwipeableDrawer
			disableSwipeToOpen={iOS}
			open={open}
			onClose={onClose}
			onOpen={() => {}}
			variant={variant}
			PaperProps={PaperProps}
		>
			<div className={classes.flexContainer}>
				<div className={classes.flexHeader}>
					<Link style={{ textDecoration: 'none', color: 'inherit' }} to={paths.home} onClick={onClose}>
						<ListItem className={classNames(classes.firebase, classes.itemCategory)}>
							<Grid container alignItems="center" spacing={2} direction="row">
								<Grid item className={classes.iconWrapper}>
									<Logo scale={0.85} palettePreference={'dark'} />
									<Typography
										className={classNames(classes.noSelect, classes.center, classes.topNameMargin)}
										color="inherit"
										variant="body1"
									>
										Stool Labeler
									</Typography>
								</Grid>
							</Grid>
						</ListItem>
					</Link>

					<Link style={{ textDecoration: 'none', color: 'inherit' }} to={paths.profile} onClick={onClose}>
						<ListItem className={classNames(classes.item, classes.itemPersonal, classes.itemActionable)}>
							<Grid container alignItems="center" spacing={1} direction="column" className={classes.personaSection}>
								<Grid item className={classes.iconWrapper}>
									<ActiveBadgeAvatar
										src={auth.picture}
										scale={1.8}
										onError={() => {
											getUserPicture()
										}}
									/>
								</Grid>
								<Grid item className={classes.iconWrapper}>
									<Typography
										className={classNames(classes.noSelect, classes.center, classes.topNameMargin)}
										color="inherit"
										variant="body1"
									>
										{safeAuthName}
									</Typography>
								</Grid>
							</Grid>
						</ListItem>
					</Link>
				</div>

				<div className={classes.flexList}>
					<List disablePadding>
						{categories.map(({ id, children, visibility }) => {
							if ((isMonitoring && !visibility.clinical) || (!isMonitoring && !visibility.individual)) {
								return <span key={id}></span>
							}
							return (
								<React.Fragment key={id}>
									<ListItem className={classes.categoryHeader}>
										<ListItemText
											classes={{
												primary: classes.categoryHeaderPrimary,
											}}
										>
											{id}
										</ListItemText>
									</ListItem>
									{children.map(({ id: childId, icon, visibility, page = null }) => {
										const shouldRender =
											(page && isMonitoring && visibility.clinical) ||
											(!isMonitoring && visibility.individual)
										return shouldRender ? (
											<ListItem key={childId} dense className={classNames(classes.item)}>
												<Button
													to={page}
													onClick={onClose}
													key={childId}
													variant="contained"
													color="primary"
													startIcon={icon}
													className={classNames(
														(location && page ? location === page : false) && 'active',
														classes.navButton,
														(location && page ? location === page : false) &&
															classes.itemActiveItem
													)}
												>
													<ListItemText
														className={classes.navItemText}
														classes={{
															primary: classes.itemPrimary,
															textDense: classes.textDense,
														}}
													>
														{childId}
													</ListItemText>
												</Button>
											</ListItem>
										) : (
											<div />
										)
									})}
									<Divider className={classes.divider} />
								</React.Fragment>
							)
						})}
					</List>
				</div>
				<div className={classes.flexFooter}>
					<BottomNavigation
						value={value}
						onChange={(event, newValue) => {
							if (newValue === 'help') {
								setValue(0)
							} else {
								setValue(newValue)
							}
						}}
						showLabels
					>
						{/* <BottomNavigationAction label="Help" value="help" icon={<LiveHelpIcon />} /> */}

						{/* <BottomNavigationAction
							label="Lock"
							value="lock"
							icon={<HttpsIcon />}
							onClick={() => unlock(true)}
						/> */}
						<BottomNavigationAction
							label="Log Out"
							value="logout"
							icon={<ExitToAppIcon />}
							onClick={() => logoutUser()}
						/>
					</BottomNavigation>
				</div>
			</div>

			<ResponsiveFullscreenDialog
				open={locked}
				onClose={(bool) => {
					unlock(bool)
					setValue(0)
				}}
			/>
		</SwipeableDrawer>
	)
}

Navigator.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string,
	location: PropTypes.string,
	auth: PropTypes.shape({
		accessToken: PropTypes.string,
		name: PropTypes.string,
		nickname: PropTypes.string,
		roles: PropTypes.array,
	}).isRequired,
	logoutUser: PropTypes.func.isRequired,
	refreshToken: PropTypes.func.isRequired,
	getUserPicture: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default connect(mapStateToProps, actions)(withStyles(styles)(Navigator))
