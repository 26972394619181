/**
 * Auth Actions
 */
// LOGIN
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'
export const LOGIN_USER = 'LOGIN_USER'

export const IMPERSONATE_USER = 'IMPERSONATE_USER'
export const IMPERSONATE_USER_FAIL = 'IMPERSONATE_USER_FAIL'
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS'

// LOGOUT
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL'
export const LOGOUT_USER = 'LOGOUT_USER'

// USER
export const GET_USER_PICTURE_SUCCESS = 'GET_USER_PICTURE_SUCCESS'

// REFRESH
export const REFRESH_SESSION_SUCCESS = 'REFRESH_SESSION_SUCCESS'
