import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import HelpIcon from '@material-ui/icons/Help'
import Hidden from '@material-ui/core/Hidden'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import StopIcon from '@material-ui/icons/Stop'
import ForumIcon from '@material-ui/icons/Forum'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { paths } from 'routes'
import { navigate } from 'gatsby'

const lightColor = 'rgba(255, 255, 255, 0.7)'

const styles = (theme) => ({
	menuButton: {
		marginLeft: theme.spacing(-1),
	},
	button: {
		borderColor: lightColor,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
})

function Header({ classes, auth, labeling, onDrawerToggle, onPanelToggle, title }) {
	const hasRoles = (auth?.app_metadata?.roles || []).length

	return (
		<>
		</>
		// <>
		// 	<AppBar color="primary" position="sticky" className={classes.appBar}>
		// 		<Toolbar>
		// 			<Grid container spacing={2} alignItems="center">
		// 				<Hidden smUp>
		// 					<Grid item>
		// 						<IconButton
		// 							color="inherit"
		// 							aria-label="Open drawer"
		// 							onClick={onDrawerToggle}
		// 							className={classes.menuButton}
		// 						>
		// 							<MenuIcon />
		// 						</IconButton>
		// 					</Grid>
		// 				</Hidden>

        //     {/* <Grid item>{labeling.currentDatabase ? `Database: ${labeling.currentDatabase.split('-')[0]}-${labeling.currentDatabase.split('-').pop()}` : "No database selected"}</Grid>
		// 				<Grid item xs />

		// 				<Grid item>
        //       <Button
        //         className={classes.button}
        //         variant="outlined"
        //         color="inherit"
        //         size="small"
        //         onClick={() => {
        //           window.open(
        //             'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Videos/LabelerWorkflow.mp4'
        //           )
        //         }}
        //       >
        //         Instructions: Labeler team Workflow
        //       </Button>
        //     </Grid> */}
        //     {/* <Grid item>
        //       <Button
        //         className={classes.button}
        //         variant="outlined"
        //         color="inherit"
        //         size="small"
        //         onClick={() => {
        //           window.open(
        //             'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Videos/QAWorkflow.mp4'
        //           )
        //         }}
        //       >
        //         Instructions: QA Workflow
        //       </Button>
        //     </Grid> */}
        //     {/* <Grid item>
		// 					<Button
		// 						className={classes.button}
		// 						variant="outlined"
		// 						color="inherit"
		// 						size="small"
		// 						onClick={() => {
		// 							window.open(
		// 								'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Videos/TaskTable.mp4'
		// 							)
		// 						}}
		// 					>
		// 						General Instructions: Tasks Table
		// 					</Button>
		// 				</Grid> */}
		// 				{/* <Grid item>
		// 					<Button
		// 						className={classes.button}
		// 						variant="outlined"
		// 						color="inherit"
		// 						size="small"
		// 						onClick={() => {
		// 							window.open(
		// 								'https://dieta-public-resources.s3-us-west-2.amazonaws.com/Videos/LabelingInterface.mp4'
		// 							)
		// 						}}
		// 					>
		// 						General Instructions: Labeling Interface
		// 					</Button>
		// 				</Grid> */}
		// 				{/* <Grid item>
		// 					<Tooltip arrow title="Alerts • No alters" aria-label="alerts • no alerts">
		// 						<IconButton color="inherit" aria-label="Open panel" onClick={onPanelToggle}>
		// 							<NotificationsIcon />
		// 						</IconButton>
		// 					</Tooltip>
		// 				</Grid> */}
		// 			</Grid>
		// 		</Toolbar>
		// 	</AppBar>
		// </>
	)
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	onDrawerToggle: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	auth: PropTypes.shape({
		app_metadata: PropTypes.shape({
			roles: PropTypes.array,
		}),
	}),
  labeling: PropTypes.shape({
    currentDatabase: PropTypes.string
  })
}

const mapStateToProps = ({ auth, labeling }) => {
	return {
		auth,
    labeling
	}
}
export default compose(connect(mapStateToProps, actions), withStyles(styles), withTranslation())(Header)
