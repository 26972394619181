import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import TagFacesIcon from '@material-ui/icons/TagFaces'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	pillGroup: {
		display: 'flex',
		justifyContent: 'center',
	},
	labelValue: {
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
	},
}))

const LabelChipList = (props) => {
	const classes = useStyles()
	const chipData = props.data

	// const handleDelete = (chipToDelete) => () => {
	//   setChipData((chips) =>
	//     chips.filter((chip) => chip.key !== chipToDelete.key),
	//   );
	// };

	return (
		<Paper component="ul" className={classes.root}>
			{chipData.map((data) => {
				let icon

				return (
					<li key={data.key}>
						<div className={classes.pillGroup}>
							<Chip
								icon={icon}
								label={data.name}
								onDelete={props.handleDelete && props.handleDelete(data)}
								className={classes.chip}
							/>
							<Divider orientation={'vertical'} />
							<Typography className={classes.labelValue} variant="subtitle1">
								{data.value}
							</Typography>
						</div>
					</li>
				)
			})}
		</Paper>
	)
}

export { LabelChipList }
