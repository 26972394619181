import * as types from 'action_types'
import * as actions from 'actions'
import { mergeDeep, deepMerge } from 'reducers/z_redux_helpers'

const INITIAL_STATE = {
	locale: 'en',
	settings: {},
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		
		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		default:
			return state
	}
}
