export const handleSuccess = async (dispatch, type, payload = null, meta = {}) => {
	await dispatch({ type, payload, meta })
	return payload
}

export const handleFail = async (dispatch, type, error) => {
	await dispatch({ type: 'ERROR', payload: { error } })
	await dispatch({ type, payload: { error } })
	return { error }
}
