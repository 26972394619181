export const SET_LABELING_ENTRY_UID = 'SET_LABELING_ENTRY_UID'
export const SET_LABELING_WORKLIST = 'SET_LABELING_WORKLIST'
export const SET_LABELING_VISIBILITY = 'SET_LABELING_VISIBILITY'
export const SET_INTERESTED_CHARACTERISTICS = 'SET_INTERESTED_CHARACTERISTICS'
export const SET_LABELING_VISIBILE_GUIDE = 'SET_LABELING_VISIBILE_GUIDE'
export const SET_LABELING_DB = 'SET_LABELING_DB'
export const SET_LABELING_INSTRUCTIONS_VISIBLE = 'SET_LABELING_INSTRUCTIONS_VISIBLE'
export const SET_LABELING_TABLE_RENDERED = 'SET_LABELING_TABLE_RENDERED'
export const SET_LABELING_GUIDE_PREFERENCE = 'SET_LABELING_GUIDE_PREFERENCE'
export const SET_LABELING_IMAGE_ZOOM_PREFERENCE = 'SET_LABELING_IMAGE_ZOOM_PREFERENCE'

export const SET_LABELING_PROFILE = "SET_LABELING_PROFILE"
export const SET_LABELING_STORES = "SET_LABELING_STORES"
export const TOGGLE_SUBMISSION_LOCK = "TOGGLE_SUBMISSION_LOCK"
export const SET_TEAM_SIZE = "SET_TEAM_SIZE"
export const SET_TEAM_MEMBER_ID = "SET_TEAM_MEMBER_ID"