import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import Tooltip from '@material-ui/core/Tooltip'

import moment from 'moment'
import { TimeSeconds } from 'utils/helpers'
import Color from 'color'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Name', format: (value) => value },
	{
		id: 'symptom',
		align: 'center',
		numeric: false,
		disablePadding: false,
		label: 'Symptom',
		format: (value) => value,
	},
	{
		id: 'duration',
		align: 'center',
		numeric: true,
		disablePadding: false,
		label: 'Duration',
		format: (value) => value,
	},
	{ id: 'assign', align: 'center', numeric: false, disablePadding: false, label: 'Assign', format: (value) => value },
]

function EnhancedTableHead(props) {
	const { classes, order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell />
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
})

const effectIcon = (effect) => {
	if (effect === null) {
		return <></>
	} else if (effect === 50) {
		return <TrendingFlatIcon color={'disabled'} />
	} else if (effect < 50) {
		return <TrendingDownIcon color={'error'} />
	} else {
		return <TrendingUpIcon color={'primary'} />
	}
}

function Row(props) {
	const { row, setCurrentIntervention, handleClickOpenInterventionCreate } = props
	const [open, setOpen] = React.useState(false)
	const classes = useRowStyles()
	// console.log(row)
	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton color="inherit" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row" align="left">
					{row.meta?.friendlyName || row.name}
				</TableCell>
				<TableCell align="center">{row.symptom}</TableCell>
				<TableCell align="center">{row.duration ? TimeSeconds(row.duration, 1) : 'Self Bounded'}</TableCell>

				<TableCell align="center">
					<Grid container direction="row" alignItems="center" justifyContent="center">
						<Tooltip title="Create custom experiment based on this one">
							<label htmlFor="fork-intervention-button">
								<IconButton
									color="primary"
									aria-label="fork intervention"
									component="span"
									onClick={() => {
										setCurrentIntervention(row)
										handleClickOpenInterventionCreate(true)
									}}
								>
									<FontAwesomeIcon icon={faCodeBranch} />
								</IconButton>
							</label>
						</Tooltip>
						<Tooltip title="Assign this experiment now">
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setCurrentIntervention(row)
									handleClickOpenInterventionCreate(false)
								}}
							>
								Assign
							</Button>
						</Tooltip>
					</Grid>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell
											style={{
												borderStyle: 'none',
											}}
										>
											Details
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableCell
										style={{
											borderStyle: 'none',
										}}
										component="th"
										scope="row"
										align="left"
									>
										{row.recommendation}
									</TableCell>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

Row.propTypes = {
	row: PropTypes.shape({
		name: PropTypes.string.isRequired,
		symptom: PropTypes.string.isRequired,
		duration: PropTypes.number,
		meta: PropTypes.shape({
			theme: PropTypes.string.isRequired,
			friendlyName: PropTypes.string.isRequired,
		}),
	}).isRequired,
}

const useStyles = makeStyles((theme) => ({
	wrapper: {
		margin: theme.spacing(2),
	},
	container: {
		maxHeight: 440,
	},
	titleBar: {
		padding: theme.spacing(1),
	},
	interventionGroupHeader: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
      ${
			theme.palette.mode === 'dark'
				? Color(theme.palette.background.paper).lighten(0.7)
				: Color(theme.palette.background.paper).darken(0.2)
		} 3%,
      ${theme.palette.background.paper} 90%)`,
	},
}))

function InterventionsOptionsTable(props) {
	const classes = useStyles()
	const { interventions, title, setCurrentIntervention, handleClickOpenInterventionCreate } = props
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('symptom')
	const [searchVal, setSearchVal] = React.useState('')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const filteredInterventions = searchVal
		? interventions.reduce((acc, item) => {
				if (
					item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
					item.recommendation.toLowerCase().includes(searchVal.toLowerCase()) ||
					item.symptom.toLowerCase().includes(searchVal.toLowerCase())
				) {
					acc.push(item)
				}
				return acc
		  }, [])
		: interventions || []

	return (
		<>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Typography variant="h5" color="textSecondary" component="h5">
						{title}
					</Typography>
				</Toolbar>
			</AppBar>
			<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<SearchIcon className={classes.block} color="inherit" />
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								value={searchVal}
								onChange={(event) => {
									setSearchVal(event.target.value)
								}}
								placeholder="Search by keyword"
								InputProps={{
									disableUnderline: true,
									className: classes.searchInput,
								}}
							/>
						</Grid>

						<Grid item>
							<Tooltip arrow title="Reload" aria-label="reload">
								<IconButton color="inherit">
									<RefreshIcon className={classes.block} color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<div className={classes.wrapper}>
				<TableContainer component={Paper} className={classes.container}>
					<Table stickyHeader aria-label="collapsible table">
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={filteredInterventions.length}
						/>
						{/* <TableHead>
							<TableRow>
								<TableCell />
								<TableCell>Name</TableCell>
								<TableCell align="right">Symptom</TableCell>
								<TableCell align="right">Duration</TableCell>
								<TableCell align="right">Assign</TableCell>
							</TableRow>
						</TableHead> */}
						<TableBody>
							{stableSort(filteredInterventions, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, i) => (
									<Row key={i} row={row} {...props} />
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={filteredInterventions.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div>
		</>
	)
}

export { InterventionsOptionsTable }
