import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import ImageGallery from 'react-image-gallery'

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}))

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="up" ref={ref} {...props} />
// })

function ImageCarouselDialog({ open, entityImages, startIndex = 0, handleClickOpen, handleClose }) {
	const classes = useStyles()
	const images = entityImages.map((image) => {
		return {
			original: image.uri,
			thumbnail: image.uri,
		}
	})
	return (
		<div>
			<Dialog fullScreen open={open} TransitionProps={{ onClose: handleClose }}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Typography variant="h6" className={classes.title}></Typography>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<ImageGallery
					thumbnailPosition={'left'}
					showPlayButton={false}
					items={images}
					startIndex={startIndex}
					renderItem={(item) => {
						return (
							<div className="image-gallery-image">
								<img
									src={item.original}
									style={{
										maxHeight: `calc(100vh - 46px)`,
										maxWidth: '100vw',
										objectFit: 'scale-down',
										fontFamily: "'object-fit: scale-down'",
									}}
								/>
							</div>
						)
					}}
				/>
			</Dialog>
		</div>
	)
}

export { ImageCarouselDialog }
