import axiosInstance from 'src/axiosConfig'
import {
	paginateDefaultArgs,
	paginateExhaust,
	getAddedItemsInArrayByID,
	getRemovedItemsInArrayByID,
} from 'api/api_utils'

const formatMealData = ({ occurred_at, notes, items, name, media, uuid }) => ({
	...(name && { name }), // only add name if it's present to prevent null name warning from API
	occurred_at,
	notes,
	items,
	media,
	uuid,
})

const formatMealItemData = ({ name, ingredients }) => ({
	name,
	ingredients,
})

const formatMealIngredientData = ({ name }) => ({
	name,
})

// MEAL BASE - CRUD
export const fetchMeals = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/meals',
			method: 'get',
		},
		'meals',
		1,
		[],
		reduxEvent
	)
}

export const getMeal = (mealId) => {
	return axiosInstance.request({
		url: `v1/meals/${mealId}`,
		method: 'get',
	})
}

export const getMealMedia = (mealData) => {
	return axiosInstance.request({
		url: `v1/meals/${mealData.id}/media`,
		method: 'get',
	})
}

export const addMeal = (mealData) => {
	return axiosInstance
		.request({
			url: 'v1/meals',
			method: 'post',
			data: formatMealData(mealData),
		})
		.then(async (meal) => {
			// add all mealItems
			if (mealData.items.length > 0) {
				await bulkAttachMealItems(meal.data.id, mealData.items)
				return getMeal(meal.data.id)
			}

			return meal
		})
		.then(async (meal) => {
			// check if any meal items have ingredients
			if (
				mealData.items.length > 0 &&
				mealData.items.some((item) => item.ingredients && item.ingredients.length > 0)
			) {
				for (const item of mealData.items) {
					await bulkAttachMealItemIngredients(meal.data.id, item.id, item.ingredients)
				}
				return await getMeal(meal.data.id)
			}

			return meal
		})
}

export const updateMeal = (nextMealData, previousMealData) => {
	const addedItems = getAddedItemsInArrayByID(previousMealData.items, nextMealData.items)

	const removedItems = getRemovedItemsInArrayByID(previousMealData.items, nextMealData.items)

	return axiosInstance
		.request({
			url: `v1/meals/${nextMealData.id}`,
			method: 'put',
			data: formatMealData(nextMealData),
		})
		.then(async (meal) => {
			if (addedItems.length > 0 || removedItems.length > 0) {
				await bulkDetachMealItems(meal.data.id, removedItems)
				await bulkAttachMealItems(meal.data.id, addedItems)
				return await getMeal(meal.data.id)
			} else {
				return meal
			}
		})
		.then(async (meal) => {
			for (const item of addedItems) {
				await bulkAttachMealItemIngredients(meal.data.id, item.id, item.ingredients)
			}
			return await getMeal(meal.data.id)
		})
}

export const removeMeal = (mealData) => {
	return axiosInstance.request({
		url: `v1/meals/${mealData.id}`,
		method: 'delete',
	})
}

// MEAL ITEM - CRUD
export const fetchMealItems = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/mealItems?${queryString}`,
		method: 'get',
	})
}

export const getMealItem = (mealItemData) => {
	return axiosInstance.request({
		url: `v1/mealItems/${mealItemData.id}`,
		method: 'get',
	})
}

export const addMealItem = (mealItemData) => {
	return axiosInstance.request({
		url: 'v1/mealItems',
		method: 'post',
		data: formatMealItemData(mealItemData),
	})
}

export const updateMealItem = (mealItemData) => {
	return axiosInstance.request({
		url: `v1/mealItems/${mealItemData.id}`,
		method: 'put',
		data: formatMealItemData(mealItemData),
	})
}

export const removeMealItem = (mealItemData) => {
	return axiosInstance.request({
		url: `v1/mealItems/${mealItemData.id}`,
		method: 'delete',
	})
}

// MEAL ITEM INGREDIENT  - CRUD
export const fetchMealIngredients = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/mealIngredients?${queryString}`,
		method: 'get',
	})
}

export const getMealIngredient = (mealIngredientData) => {
	return axiosInstance.request({
		url: `v1/mealIngredients/${mealIngredientData}`,
		method: 'get',
	})
}

export const addMealIngredient = (mealIngredientData) => {
	return axiosInstance.request({
		url: 'v1/mealIngredients',
		method: 'post',
		data: formatMealIngredientData(mealIngredientData),
	})
}

export const updateMealIngredient = (mealIngredientData) => {
	return axiosInstance.request({
		url: `v1/mealIngredients/${mealIngredientData.id}`,
		method: 'put',
		data: formatMealIngredientData(mealIngredientData),
	})
}

export const removeMealIngredient = (mealIngredientData) => {
	return axiosInstance.request({
		url: `v1/mealIngredients/${mealIngredientData.id}`,
		method: 'delete',
	})
}

// ITEM HELPERS
const bulkAttachMealItems = (mealId, mealItems) => {
	return (
		mealItems.length &&
		axiosInstance.request({
			url: `v1/meals/${mealId}/items`,
			method: 'post',
			data: {
				item_id: mealItems.map((mealItem) => mealItem.id).filter((d) => d),
			},
		})
	)
}

const bulkDetachMealItems = (mealId, mealItems) => {
	return (
		mealItems.length &&
		axiosInstance.request({
			url: `v1/meals/${mealId}/items`,
			method: 'delete',
			data: {
				item_id: mealItems.map((mealItem) => mealItem.id).filter((d) => d),
			},
		})
	)
}

// INGREDIENT HELPERS
const bulkAttachMealItemIngredients = (mealId, mealItemId, mealItemIngredients = []) => {
	return (
		mealItemIngredients.length &&
		axiosInstance.request({
			url: `v1/meals/${mealId}/items/${mealItemId}/ingredients`,
			method: 'post',
			data: {
				ingredient_id: mealItemIngredients.map((mealItemIngredient) => mealItemIngredient.id).filter((d) => d),
			},
		})
	)
}

// const bulkDetachMealItemIngredients = (
//   mealId,
//   mealItemId,
//   mealItemIngredients
// ) => {
//   return mealItemIngredients.length
//     ? axiosInstance
//         .request({
//           url: `v1/meals/${mealId}/items/${mealItemId}/ingredients`,
//           method: "delete",
//           data: {
//             ingredient_id: mealItemIngredients.map(
//               mealItemIngredient => mealItemIngredient.id
//             ).filter(d => d)
//           }
//         })
//         .then(() => {
//           return getMeal(mealId);
//         })
//     : getMeal(mealId);
// };
