import { getBowelMedia } from 'api/bowel'
import { getMealMedia } from 'api/meal'
import { configuredStore } from 'src/configureStore'
import * as types from 'action_types'
export * from 'actions/AuthActions'
export * from 'actions/DeviceActions'
export * from 'actions/LabelingActions'

export const triggerConnectivityCheck = () => {
	return (dispatch) => {
		const isConnected = navigator.onLine
		dispatch({
			type: types.NETWORK_CONNECTION_CHANGE,
			payload: isConnected,
		})
	}
}
