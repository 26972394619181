import _merge from 'lodash.merge'

export const deepMerge = (base, marooned) => {
	return _merge(base, marooned)
}

/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => {
	return item && typeof item === 'object' && !Array.isArray(item) && item !== null
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export const mergeDeep = (target, source) => {
	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} })
				mergeDeep(target[key], source[key])
			} else {
				Object.assign(target, { [key]: source[key] })
			}
		}
	}
	return target
}

export const flatJsonAccess = (object, target) => {
	try {
		const treePath = target.split('.')
		let currentScope = object
		treePath.forEach((ancestor) => {
			currentScope = currentScope[ancestor]
		})
		return currentScope
	} catch (err) {
		return undefined
	}
}

// Finds items from a store matching the searchCriteria - preserves indecees for performance
// e.g. [id: 2, sleep_id: 2]
export const storeItemsFinder = (state, arrayProp, searchCriteria) => {
	if (Array.isArray(searchCriteria)) {
		const results = []
		searchCriteria.forEach((item) => {
			results.push(storeItemsFinder(state, arrayProp, item))
		})
		return results.filter((el) => el).shift()
	}
	const filterKeys = Object.keys(searchCriteria)
	const flatAccess = flatJsonAccess(state, arrayProp)
	const matchedEntities = flatAccess
		? flatAccess.reduce((acc, item, i) => {
				return filterKeys.every((key) => {
					return searchCriteria[key] === flatJsonAccess(item, key)
				})
					? [...acc, i]
					: acc
		  }, [])
		: []
	return matchedEntities.map((index) => {
		return {
			index,
			object: flatAccess[index],
		}
	})
}

// find and add data to the summary items that have changed
// returns a bunch of insert request objects (id, newObject) [TRANSACTIONS]
export const storeItemsTransactor = (
	state,
	arrayProp, // key in state that needs contains the data (can be flat json format for depth)
	data, // new content
	keys, // match json key criteria
	manipulator = (d, l) => {
		return l.map((map) => {
			const preserveAppData = map.object._app
			if (map.object.updated_at !== d.updated_at) {
				map.object = d
				map.object._app = d._app
			} else {
				map.object = {
					...map.object,
					...d,
				}
				map.object._app = {
					...preserveAppData,
					...d._app,
				}
			}
			return map
		})
	} // function to manipulate the stored object's properties
) => {
	let targetEntity = storeItemsFinder(state, arrayProp, keys)
	if (!targetEntity.length) {
		/* No items found, manually inject into store */
		const location = flatJsonAccess(state, arrayProp)
		location.push(data)
		targetEntity.push({
			index: location.length - 1,
			object: location[location.length - 1],
		})
	}
	return manipulator(data, targetEntity)
}

export const storeItemsMerger = (stateData, transactions) => {
	const transactionIndexList = {}
	const transactionObjects = transactions.map((item) => item.object)
	transactions.forEach((transaction) => {
		transactionIndexList[transaction.index] = transaction.object
	})
	if (!stateData.length) {
		return [...stateData, ...transactionObjects]
	}

	return stateData
		.map((entry, index) => {
			if (Object.keys(transactionIndexList).includes(index.toString())) {
				return transactionIndexList[index]
			}
			return entry
		})
		.sort((a, b) => {
			if (a.occurred_at && b.occurred_at) {
				return new Date(b.occurred_at) - new Date(a.occurred_at)
			}
			return b.id - a.id
		})
}

export const storeChildTypeRanker = (
	targetCollection = [],
	targetEntityKey = '',
	entityCollection = [],
	whitelistKeys = []
) => {
	let priorityItems = []
	entityCollection.forEach((entity) => {
		const childrenLocation = flatJsonAccess(entity, targetEntityKey)
		if (childrenLocation && childrenLocation.length) {
			const newData = childrenLocation.reduce((acc, item) => {
				if (priorityItems.findIndex((ranked) => ranked.id === item.id) === -1) {
					if (whitelistKeys.length) {
						acc.push(
							whitelistKeys.reduce((result, key) => {
								result[key] = item[key]
								return result
							}, {})
						)
					} else {
						acc.push(item)
					}
				}
				return acc
			}, [])
			priorityItems = [...priorityItems, ...newData]
		}
	})

	const deduped = targetCollection.filter((item) => {
		return priorityItems.findIndex((ranked) => ranked.id === item.id) === -1
	})
	return [...priorityItems, ...deduped]
}
