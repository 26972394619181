import React, { useCallback, useEffect, useState, useRef } from 'react'
import { makeStyles, lighten } from '@material-ui/core/styles'
import classNames from 'classnames'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import TagFacesIcon from '@material-ui/icons/TagFaces'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

import { faAssistiveListeningSystems } from '@fortawesome/free-solid-svg-icons'
import _debounce from 'lodash.debounce'
import { useHotkeys } from 'react-hotkeys-hook'
const rendered = {}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},

	hideLastBorder: {
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	},

	outline: {
		borderWidth: 1,
		borderStyle: 'solid',
	},
	highlight:
		theme.palette.mode === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
}))

const LabelUniformityMarker = (props) => {
	const classes = useStyles()
	const data = props.data

	const interestedCharacteristics = props.interestedCharacteristics
	const characteristic = interestedCharacteristics[0]
	const nonuniformName = `nonuniform_${characteristic.name}`
	const [dataMap, setDataMap] = useState(
		data.reduce((acc, item) => {
			acc[item.name] = item.value
			return acc
		}, {})
	)

	const debounceSave = useCallback((uid, changes) => {
		props.onChange(changes)
		props.onSave(uid, changes, nonuniformName)
	}, [])

	const onSelect = useCallback((uid, name, value) => {
		const changes = {
			...dataMap,
			[name]: value,
		}
		setDataMap(changes)
		debounceSave(uid, changes)
	})

	const rows = [
		{
			id: 0,
			name: nonuniformName.replace('_', ' '),
			rawName: nonuniformName,
			value: dataMap[nonuniformName] || false,
			selected: dataMap[nonuniformName] === true,
		},
	]

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="label shortcuts">
				<TableBody>
					{rows.map((row, i) => (
						<TableRow
							className={classNames([classes.hideLastBorder, { [classes.highlight]: row.selected }])}
							tabIndex={-1}
							role="checkbox"
							aria-checked={row.selected}
							hover
							key={i}
							selected={row.selected}
							onClick={(event) => onSelect(props.uid, row.rawName, !row.value)}
						>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell padding="checkbox">
								<Checkbox checked={row.selected} color="primary"onChange={(event) => event.target.blur()} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export { LabelUniformityMarker }
