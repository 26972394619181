import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiLinearProgress from '@material-ui/core/LinearProgress'

class ColoredLinearProgress extends Component {
	render() {
		const { classes, visible, ...rest } = this.props
		if (visible) {
			return <MuiLinearProgress {...rest} />
		}
		return <div style={{ height: 4 }}></div>
	}
}

const LinearProgress = ColoredLinearProgress

export { LinearProgress }
