import React, { memo } from 'react'
import { withStyles, useTheme } from '@material-ui/core/styles'

function LogoMemo({ scale = 1, palettePreference }) {
	const theme = useTheme()
	const paletteType = palettePreference || theme.palette.mode
	if (paletteType === 'light') {
		return (
			<svg
				style={{
					transform: `scale(${scale})`,
					width: '100%',
				}}
				width="180"
				height="56"
				viewBox="0 0 180 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<path
						d="M0 54.7804V0H20.2766C27.9613 0 34.5384 2.65212 40.008 7.95637C45.4776 13.2606 48.2138 19.7386 48.2164 27.3902C48.2164 35.0445 45.4803 41.5224 40.008 46.824C34.5357 52.1256 27.9586 54.7777 20.2766 54.7804H0Z"
						fill="#A2A2A2"
					/>
					<path
						d="M24.2727 14.2558C24.6025 14.2514 24.9298 14.3127 25.2354 14.4361C25.541 14.5595 25.8187 14.7425 26.0523 14.9744C26.2858 15.2036 26.4712 15.4768 26.5978 15.778C26.7244 16.0792 26.7896 16.4025 26.7896 16.729C26.7896 17.0555 26.7244 17.3788 26.5978 17.68C26.4712 17.9812 26.2858 18.2544 26.0523 18.4835C25.8175 18.7147 25.5389 18.8969 25.2327 19.0196C24.9265 19.1423 24.5988 19.203 24.2687 19.1981H13.7236V14.2558H24.2727Z"
						fill="#FFFFFF"
					/>
					<path
						d="M31.1264 21.4139C31.4563 21.4085 31.7839 21.4694 32.0896 21.5929C32.3954 21.7163 32.673 21.8998 32.906 22.1325C33.1409 22.3605 33.3275 22.633 33.455 22.934C33.5825 23.235 33.6481 23.5584 33.6481 23.885C33.6481 24.2117 33.5825 24.5351 33.455 24.8361C33.3275 25.1371 33.1409 25.4096 32.906 25.6376C32.6723 25.8688 32.3944 26.0509 32.0887 26.1729C31.783 26.295 31.4557 26.3546 31.1264 26.3482H13.7236V21.4139H31.1264Z"
						fill="#FFFFFF"
					/>
					<path
						d="M31.1465 28.492C31.4763 28.4867 31.8039 28.5475 32.1096 28.671C32.4154 28.7944 32.693 28.978 32.926 29.2106C33.1609 29.4386 33.3476 29.7111 33.475 30.0121C33.6025 30.3131 33.6681 30.6365 33.6681 30.9632C33.6681 31.2898 33.6025 31.6132 33.475 31.9142C33.3476 32.2152 33.1609 32.4877 32.926 32.7157C32.693 32.9484 32.4154 33.1319 32.1096 33.2554C31.8039 33.3788 31.4763 33.4397 31.1465 33.4343H13.7437V28.492H31.1465Z"
						fill="#FFFFFF"
					/>
					<path
						d="M24.2968 35.582C24.6265 35.5774 24.9539 35.6385 25.2596 35.7619C25.5652 35.8854 25.8429 36.0685 26.0763 36.3006C26.3097 36.5295 26.4951 36.8023 26.6216 37.1031C26.7481 37.404 26.8133 37.727 26.8133 38.0532C26.8133 38.3794 26.7481 38.7023 26.6216 39.0032C26.4951 39.3041 26.3097 39.5769 26.0763 39.8057C25.8429 40.0378 25.5652 40.221 25.2596 40.3444C24.9539 40.4678 24.6265 40.529 24.2968 40.5243H13.7437V35.582H24.2968Z"
						fill="#FFFFFF"
					/>
					<path
						d="M56.1644 3.99205C56.7978 3.35654 57.5511 2.85216 58.3811 2.50797C59.211 2.16377 60.1011 1.98657 61.0001 1.98657C61.8991 1.98657 62.7892 2.16377 63.6192 2.50797C64.4491 2.85216 65.2024 3.35654 65.8358 3.99205C66.4822 4.6157 66.9935 5.36464 67.3382 6.19268C67.6828 7.02072 67.8535 7.91031 67.8398 8.80659C67.8531 9.70341 67.6823 10.5935 67.3376 11.4221C66.993 12.2507 66.4819 13.0004 65.8358 13.6251C65.2024 14.2606 64.4491 14.765 63.6192 15.1092C62.7892 15.4534 61.8991 15.6306 61.0001 15.6306C60.1011 15.6306 59.211 15.4534 58.3811 15.1092C57.5511 14.765 56.7978 14.2606 56.1644 13.6251C55.5183 13.0004 55.0072 12.2507 54.6626 11.4221C54.318 10.5935 54.1471 9.70341 54.1604 8.80659C54.1467 7.91031 54.3174 7.02072 54.6621 6.19268C55.0067 5.36464 55.518 4.6157 56.1644 3.99205V3.99205Z"
						fill="#A2A2A2"
					/>
					<path d="M67.1101 20.2441V54.5527H54.8896V22.6394L67.1101 20.2441Z" fill="#A2A2A2" />
					<path
						d="M110.465 38.169V39.4385H84.7494C84.7494 44.5192 87.3012 47.0609 92.4048 47.0635C95.0447 47.0635 96.9913 46.0163 98.2444 43.9217H109.996C109.595 45.7339 108.829 47.4461 107.744 48.9544C106.659 50.4628 105.277 51.7359 103.683 52.6965C100.303 54.7358 96.4121 55.7794 92.4609 55.7066C86.553 55.7066 81.7528 54.1017 78.0601 50.892C74.3673 47.6823 72.529 43.1965 72.545 37.4344C72.545 31.7709 74.3019 27.301 77.8156 24.0248C81.3293 20.7486 85.9291 19.1091 91.6152 19.1064C97.5684 19.1064 102.2 20.8124 105.511 24.2244C108.822 27.6364 110.473 32.2846 110.465 38.169ZM84.9699 32.4163H99.1182C98.8357 30.8586 97.9778 29.4628 96.7134 28.504C95.4217 27.4997 93.8226 26.9683 92.1843 26.9989C90.4969 26.9561 88.8394 27.4482 87.4508 28.4042C86.1314 29.3692 85.2415 30.8084 84.9699 32.4163Z"
						fill="#A2A2A2"
					/>
					<path
						d="M131.263 30.2766V54.553H119.038V30.2766H113.479V20.2443H119.038V12.7391L131.259 9.94458V20.2443H136.573V30.2766H131.263Z"
						fill="#A2A2A2"
					/>
					<path
						d="M180 17.8489V54.5528H167.78V50.7403H167.647C166.612 52.3972 165.088 53.6942 163.283 54.453C161.377 55.2838 159.318 55.7055 157.238 55.6905C152.226 55.6905 148.073 53.8967 144.782 50.3091C141.49 46.7215 139.845 42.4073 139.848 37.3665C139.848 32.3284 141.471 28.0262 144.717 24.4599C147.964 20.8936 152.096 19.1091 157.114 19.1064C161.683 19.1064 165.242 20.6008 167.792 23.5896V20.2442L180 17.8489ZM166.068 31.9372C164.657 30.4415 162.744 29.6936 160.329 29.6936C157.913 29.6936 155.999 30.4415 154.585 31.9372C153.177 33.4263 152.418 35.4096 152.473 37.4544C152.437 38.4679 152.605 39.4784 152.968 40.4261C153.331 41.3737 153.881 42.2393 154.585 42.9715C156.004 44.3981 157.916 45.1113 160.321 45.1113C162.725 45.1113 164.639 44.3874 166.06 42.9396C166.765 42.2077 167.316 41.3423 167.679 40.3946C168.043 39.4469 168.212 38.4363 168.176 37.4224C168.223 35.3893 167.466 33.4192 166.068 31.9372V31.9372Z"
						fill="#A2A2A2"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="180" height="55.6906" fill="white" />
					</clipPath>
				</defs>
			</svg>
		)
	} else if (paletteType === 'dark') {
		return (
			<svg
				style={{
					transform: `scale(${scale})`,
					width: '100%',
				}}
				width="180"
				height="56"
				viewBox="0 0 180 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<path
						d="M0 54.7804V0H20.2766C27.9613 0 34.5384 2.65212 40.008 7.95637C45.4776 13.2606 48.2138 19.7386 48.2164 27.3902C48.2164 35.0445 45.4803 41.5224 40.008 46.824C34.5357 52.1256 27.9586 54.7777 20.2766 54.7804H0Z"
						fill="white"
					/>
					<path
						d="M24.2727 14.2558C24.6025 14.2514 24.9298 14.3127 25.2354 14.4361C25.541 14.5595 25.8187 14.7425 26.0523 14.9744C26.2858 15.2036 26.4712 15.4768 26.5978 15.778C26.7244 16.0792 26.7896 16.4025 26.7896 16.729C26.7896 17.0555 26.7244 17.3788 26.5978 17.68C26.4712 17.9812 26.2858 18.2544 26.0523 18.4835C25.8175 18.7147 25.5389 18.8969 25.2327 19.0196C24.9265 19.1423 24.5988 19.203 24.2687 19.1981H13.7236V14.2558H24.2727Z"
						fill="#8F82F4"
					/>
					<path
						d="M31.1264 21.4139C31.4563 21.4085 31.7839 21.4694 32.0896 21.5929C32.3954 21.7163 32.673 21.8998 32.906 22.1325C33.1409 22.3605 33.3275 22.633 33.455 22.934C33.5825 23.235 33.6481 23.5584 33.6481 23.885C33.6481 24.2117 33.5825 24.5351 33.455 24.8361C33.3275 25.1371 33.1409 25.4096 32.906 25.6376C32.6723 25.8688 32.3944 26.0509 32.0887 26.1729C31.783 26.295 31.4557 26.3546 31.1264 26.3482H13.7236V21.4139H31.1264Z"
						fill="#9455E5"
					/>
					<path
						d="M31.1465 28.492C31.4763 28.4867 31.8039 28.5475 32.1096 28.671C32.4154 28.7944 32.693 28.978 32.926 29.2106C33.1609 29.4386 33.3476 29.7111 33.475 30.0121C33.6025 30.3131 33.6681 30.6365 33.6681 30.9632C33.6681 31.2898 33.6025 31.6132 33.475 31.9142C33.3476 32.2152 33.1609 32.4877 32.926 32.7157C32.693 32.9484 32.4154 33.1319 32.1096 33.2554C31.8039 33.3788 31.4763 33.4397 31.1465 33.4343H13.7437V28.492H31.1465Z"
						fill="#C042DB"
					/>
					<path
						d="M24.2968 35.582C24.6265 35.5774 24.9539 35.6385 25.2596 35.7619C25.5652 35.8854 25.8429 36.0685 26.0763 36.3006C26.3097 36.5295 26.4951 36.8023 26.6216 37.1031C26.7481 37.404 26.8133 37.727 26.8133 38.0532C26.8133 38.3794 26.7481 38.7023 26.6216 39.0032C26.4951 39.3041 26.3097 39.5769 26.0763 39.8057C25.8429 40.0378 25.5652 40.221 25.2596 40.3444C24.9539 40.4678 24.6265 40.529 24.2968 40.5243H13.7437V35.582H24.2968Z"
						fill="#E03F6D"
					/>
					<path
						d="M56.1644 3.99205C56.7978 3.35654 57.5511 2.85216 58.3811 2.50797C59.211 2.16377 60.1011 1.98657 61.0001 1.98657C61.8991 1.98657 62.7892 2.16377 63.6192 2.50797C64.4491 2.85216 65.2024 3.35654 65.8358 3.99205C66.4822 4.6157 66.9935 5.36464 67.3382 6.19268C67.6828 7.02072 67.8535 7.91031 67.8398 8.80659C67.8531 9.70341 67.6823 10.5935 67.3376 11.4221C66.993 12.2507 66.4819 13.0004 65.8358 13.6251C65.2024 14.2606 64.4491 14.765 63.6192 15.1092C62.7892 15.4534 61.8991 15.6306 61.0001 15.6306C60.1011 15.6306 59.211 15.4534 58.3811 15.1092C57.5511 14.765 56.7978 14.2606 56.1644 13.6251C55.5183 13.0004 55.0072 12.2507 54.6626 11.4221C54.318 10.5935 54.1471 9.70341 54.1604 8.80659C54.1467 7.91031 54.3174 7.02072 54.6621 6.19268C55.0067 5.36464 55.518 4.6157 56.1644 3.99205V3.99205Z"
						fill="white"
					/>
					<path d="M67.1101 20.2441V54.5527H54.8896V22.6394L67.1101 20.2441Z" fill="white" />
					<path
						d="M110.465 38.169V39.4385H84.7494C84.7494 44.5192 87.3012 47.0609 92.4048 47.0635C95.0447 47.0635 96.9913 46.0163 98.2444 43.9217H109.996C109.595 45.7339 108.829 47.4461 107.744 48.9544C106.659 50.4628 105.277 51.7359 103.683 52.6965C100.303 54.7358 96.4121 55.7794 92.4609 55.7066C86.553 55.7066 81.7528 54.1017 78.0601 50.892C74.3673 47.6823 72.529 43.1965 72.545 37.4344C72.545 31.7709 74.3019 27.301 77.8156 24.0248C81.3293 20.7486 85.9291 19.1091 91.6152 19.1064C97.5684 19.1064 102.2 20.8124 105.511 24.2244C108.822 27.6364 110.473 32.2846 110.465 38.169ZM84.9699 32.4163H99.1182C98.8357 30.8586 97.9778 29.4628 96.7134 28.504C95.4217 27.4997 93.8226 26.9683 92.1843 26.9989C90.4969 26.9561 88.8394 27.4482 87.4508 28.4042C86.1314 29.3692 85.2415 30.8084 84.9699 32.4163Z"
						fill="white"
					/>
					<path
						d="M131.263 30.2766V54.553H119.038V30.2766H113.479V20.2443H119.038V12.7391L131.259 9.94458V20.2443H136.573V30.2766H131.263Z"
						fill="white"
					/>
					<path
						d="M180 17.8489V54.5528H167.78V50.7403H167.647C166.612 52.3972 165.088 53.6942 163.283 54.453C161.377 55.2838 159.318 55.7055 157.238 55.6905C152.226 55.6905 148.073 53.8967 144.782 50.3091C141.49 46.7215 139.845 42.4073 139.848 37.3665C139.848 32.3284 141.471 28.0262 144.717 24.4599C147.964 20.8936 152.096 19.1091 157.114 19.1064C161.683 19.1064 165.242 20.6008 167.792 23.5896V20.2442L180 17.8489ZM166.068 31.9372C164.657 30.4415 162.744 29.6936 160.329 29.6936C157.913 29.6936 155.999 30.4415 154.585 31.9372C153.177 33.4263 152.418 35.4096 152.473 37.4544C152.437 38.4679 152.605 39.4784 152.968 40.4261C153.331 41.3737 153.881 42.2393 154.585 42.9715C156.004 44.3981 157.916 45.1113 160.321 45.1113C162.725 45.1113 164.639 44.3874 166.06 42.9396C166.765 42.2077 167.316 41.3423 167.679 40.3946C168.043 39.4469 168.212 38.4363 168.176 37.4224C168.223 35.3893 167.466 33.4192 166.068 31.9372V31.9372Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="180" height="55.6906" fill="white" />
					</clipPath>
				</defs>
			</svg>
		)
	}
}

const Logo = memo(LogoMemo)

export { Logo }
