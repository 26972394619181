import React from 'react'
import { navigate } from 'gatsby'
import MuiButton from '@material-ui/core/Button'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'

const Button = (props) => {
	return (
		<MuiButton
			{...props}
			onClick={() => {
				if (props.onClick) {
					props.onClick()
				}
				if (props.to) {
					navigate(props.to)
				}
			}}
		/>
	)
}
export { Button }
