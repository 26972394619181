import React from 'react'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'

const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '100%',
			animation: '$ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}))(Badge)

const SmallAvatar = withStyles((theme) => ({
	root: (props) => ({
		border: `2px solid ${theme.palette.background.paper}`,
	}),
}))(Avatar)

const useStyles = (props) =>
	makeStyles((theme) => ({
		root: {
			display: 'flex',
			transform: `scale(${props.scale || 1})`,
			'& > *': {
				margin: `calc(${theme.spacing(1)} * ${props.scale})`,
			},
		},
	}))

const ActiveBadgeAvatar = (props) => {
	const classes = useStyles(props)()
	const theme = useTheme()

	return (
		<div className={classes.root}>
			<StyledBadge
				overlap="circular"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				variant="dot"
			>
				<Avatar
					src={props.src}
					imgProps={{
						onError: props.onError,
					}}
				/>
			</StyledBadge>
		</div>
	)
}

const PictureInPictureAvatar = (props) => {
	const classes = useStyles(props)()
	const theme = useTheme()
	return (
		<div className={classes.root}>
			<Badge
				overlap="circular"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				badgeContent={
					<SmallAvatar
						src={props.childSrc}
						style={{
							transform: `scale(.55)`,
						}}
						imgProps={{
							onError: props.onChildError,
						}}
					/>
				}
			>
				<Avatar
					src={props.src}
					imgProps={{
						onError: (e) => {
							props.onError()
						},
					}}
				/>
			</Badge>
		</div>
	)
}

export { ActiveBadgeAvatar, PictureInPictureAvatar }
