import React, { useRef, useCallback, useEffect, useState, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import MaterialTable from '@material-table/core'
import _debounce from 'lodash.debounce'
import { useDebouncedEffect } from "utils/helpers"
import { useFirestore } from 'react-redux-firebase'
import { snackActions } from 'src/utils/SnackbarUtils'

const generateIDfromUUID = (uuid) => {
  var hex = "0x" + uuid.replace(/-/g, "");
  var value = window.BigInt(hex);
  var decimal = value.toString(); // don't convert this to a number.
  return parseInt(decimal.substr(decimal.length - 5))
}

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	divider: {
		borderColor: '#aaa',
	},
}))

const generateTableData = (data, role, currentDatabase, requiredWork, interestedCharacteristics, teamSize = 1, teamMemberId = 1) => {
	return (data || []).map((document) => {
    const teamID = generateIDfromUUID(document.id)
		const labels = Object.keys(document.labels || {})
		const userSetLabels = labels.filter((label) => label.startsWith('_')).filter((label) => label !== "compactness")
		const operatorSetLabels = labels.filter((label) => !label.startsWith('_')).filter((label) => label !== "compactness")
		const doctorCount = operatorSetLabels
			.filter((a) => !a.includes('nonuniform'))
			.filter((a) => a)
			.join(', ')
			.replace(/_/g, '')
			.split(',')
			.filter((a) => a).length
		const workSet = new Set(requiredWork)
		operatorSetLabels.forEach((label) => {
			workSet.delete(label)
		})
    const redundencyCount = document.history ? Object.keys(document.history).length : 0
		const data = {
			id: document.id,
      redundencyCount,
			status: (role === "Admin" || role === "QA") && currentDatabase.endsWith('QA') ? (redundencyCount >= 2 ? 'Finished ✅' : `❗${2 - redundencyCount} incomplete❗`) : doctorCount === requiredWork.length
   ? 'Finished ✅'
   : `❗${requiredWork.length - doctorCount} incomplete❗`,
			teamId: (teamID % teamSize) + 1,
			filesCount: document.images.length || 0,
			userLabels: userSetLabels.join(', ').replace(/_/g, ''),
			operatorLabels: operatorSetLabels.join(', '),
			incompleteLabels: Array.from(workSet).join(', '),
			labelConfidence:
				interestedCharacteristics.length > 1
					? null
					: document.meta.confidence
					? document.meta.confidence[interestedCharacteristics[0]]
					: null,
			createdAt: document.meta.created_at,
      occurredAt: document.meta.occurred_at,
			labeledAt:
				interestedCharacteristics.length > 1
					? document.meta.labeled_at
					: document.meta[`${interestedCharacteristics[0]}_labeled_at`],
		}

		return data
	}).filter((row) => {
    return row.teamId === teamMemberId
  })
}

const EntitySummaryList = (props) => {
	const classes = useStyles()
	const {
		admin = false,
    role,
		data = [],
		requiredWork,
		setTableWorklist,
		setTableVisibility,
		setLabelingId,
		labelerOpen,
		currentDatabase,
		setRenderedTable,
		interestedCharacteristics,
		setLabelingImageZoomPreference,
    submissionModeActive,
    teamMemberId,
    teamSize
	} = props

	let [tableData, setTableData] = useState(generateTableData(data, role, currentDatabase, requiredWork, interestedCharacteristics, teamSize, teamMemberId))
	let [columnObject, setColumnObject] = useState(
		[
			{ title: 'Status', field: 'status' },
			{ title: 'ID', field: 'id' },
      // { title: 'Team Id', field: 'teamId', type: 'number', defaultFilter: teamMemberId.toString() },
			(role === "Admin" || role === "QA") && currentDatabase.endsWith('QA') ? { title: 'Label Redundency', field: 'redundencyCount' } : null,
			{ title: 'Incomplete Labels', field: 'incompleteLabels' },
			{ title: 'Finished Labels', field: 'operatorLabels' },
			// admin ? { title: 'Label Confidence', field: 'labelConfidence' } : null,
      // admin ? { title: 'Occurred At', field: 'occurredAt', type: 'date', defaultSort: 'desc' } : null,
			{ title: 'Created At', field: 'createdAt', type: 'date', defaultSort: 'desc' },
			{ title: 'Labeled At', field: 'labeledAt', type: 'date', defaultSort: 'desc' },
		].filter((d) => d)
	)

  const submitHandler = async (rowData, notify = true, rerender = true) => {
    console.log('Submitting: ', rowData.id);
    if (currentDatabase.startsWith('queue')) {
      if (currentDatabase.endsWith('QA')) {
        const source = await fireStore.collection(currentDatabase).doc(rowData.id).get()
        if (source.exists) {
          const dataItem = source.data()
          await fireStore.collection('dieta-stool-training-data-production').doc(rowData.id).set(dataItem, {merge:true});
          await fireStore.collection(currentDatabase).doc(rowData.id).delete();
          try { await fireStore.collection("queue-stool-training-data-CLFA-A").doc(rowData.id).delete(); } catch (err) {}
          try { await fireStore.collection("queue-stool-training-data-CLFA-B").doc(rowData.id).delete(); } catch (err) {}
          if (notify) snackActions.success("Successfully submitted to Production")
          if (rerender) {
            const tableData = generateTableData(data, role, currentDatabase, requiredWork, interestedCharacteristics, teamSize, teamMemberId)
            setTableData(tableData)
            if (tableRef && tableRef.current) {
              setRenderedTable(tableRef.current.state.data)
            }
          }
        }
      } else {
        const targetArr = currentDatabase.split('-')
        targetArr.pop()
        const target = [...targetArr, 'QA'].join('-')
        const source = await fireStore.collection(currentDatabase).doc(rowData.id).get()
        if (source.exists) {
          const dataItem = source.data()
          await fireStore.collection(target).doc(rowData.id).set(dataItem, {merge:true});
          await fireStore.collection(currentDatabase).doc(rowData.id).delete();
          if (notify) snackActions.success("Successfully submitted to QA")
          if (rerender) {
            const tableData = generateTableData(data, role, currentDatabase, requiredWork, interestedCharacteristics, teamSize, teamMemberId)
            setTableData(tableData)
            if (tableRef && tableRef.current) {
              setRenderedTable(tableRef.current.state.data)
            }
          }
        }
      }
    } else {
      if (notify) snackActions.error("Operation not supported from current database")
    }
  }

  const fireStore = useFirestore()
	const tableRef = useRef(null)

	const onRenderedDataChanged = (subset) => {
		// if (tableRef && tableRef.current) {
		// 	setRenderedTable(tableRef.current.state.data)
		// }
	}

  useDebouncedEffect(() => {
    const tableData = generateTableData(data, role, currentDatabase, requiredWork, interestedCharacteristics, teamSize, teamMemberId)
    setTableData(tableData)
    if (tableRef && tableRef.current) {
      setRenderedTable(tableRef.current.state.data)
    }
  }, 1000, [data, currentDatabase, labelerOpen, interestedCharacteristics, teamSize, teamMemberId])

	return (
		<Grid container direction="column" spacing={2}>
      
      {submissionModeActive ? (<Grid item xs><Grid container spacing={2}><Grid item xs></Grid><Grid item><Button color="primary" variant="contained" onClick={async () => {
        const target = currentDatabase.endsWith('QA') ? "production" : "QA"
        const uuids = tableRef.current.state.data.map((item) => {
          return item.id;
        })
        const answer = window.confirm(`Submit ${tableRef.current.state.data.length} tasks to ${target}? \n${uuids.join('\n')}`)
        if (answer) {
          snackActions.info("Initiating submission batch, please don't close window.  This may take several minutes")
          const promises = []
          for (let item of tableRef.current.state.data) {
            promises.push(submitHandler(item, false, false))
          }
          await Promise.all(promises)
          const tableData = generateTableData(data, role, currentDatabase, requiredWork, interestedCharacteristics, teamSize, teamMemberId)
          setTableData(tableData)
          if (tableRef && tableRef.current) {
            setRenderedTable(tableRef.current.state.data)
          }
          snackActions.success("Successfully submitted batch")
        } else {
          snackActions.info("Aborted request")
        }
       
      }}>Submit Currently Visible Query</Button></Grid></Grid></Grid>) : (<></>)}
      
      <Grid item xs>
        <MaterialTable
          icons={tableIcons}
          title="Stool Dataset Worklist"
          tableRef={tableRef}
          columns={columnObject}
          data={tableData}
          options={{
            selection: false, // ?
            filtering: true,
            sorting: true,
            actionsColumnIndex: -1,
            pageSize: 300,
            pageSizeOptions: [500, 1000],
          }}
          onOrderChange={(index, direction) => {
            onRenderedDataChanged(tableRef.current.state.data)
          }}
          onFilterChange={() => {
            onRenderedDataChanged(tableRef.current.state.data)
          }}
          onSearchChange={() => {
            onRenderedDataChanged(tableRef.current.state.data)
          }}
          onSelectionChange={(rows) => console.log('You selected ' + rows.length + ' rows')}
          actions={[
              submissionModeActive ? {
              icon: 'check',
              tooltip: 'Submit',
              name: "Submit",
              onClick: async (event, rowData) => {
                submitHandler(rowData)
              }
            } : {
              icon: 'edit',
              tooltip: 'Annotate',
              name: "Annotate",
              onClick: (event, rowData) => {
                if (interestedCharacteristics.includes('volume') || interestedCharacteristics.includes('buoyancy')) {
                  setLabelingImageZoomPreference('original')
                }
                setTableWorklist(tableRef.current.state.data)
                setLabelingId(rowData.id)
                setTableVisibility(true)
              },
              disabled: interestedCharacteristics.length === 0
            }
          ].filter((d) => d)}
          components={{
            Action: (props) => {
              return (
                <>
                  <Button
                    onClick={(event) => props.action.onClick(event, props.data)}
                    color="primary"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    size="small"
                    disabled={props.action.disabled}
                  >
                    {props.action.name}
                  </Button>
                </>
              )
            },
          }}
        />
      </Grid>
			
		</Grid>
	)
}

export { EntitySummaryList }
