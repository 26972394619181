module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-analytics/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"M07JXBzMxtTNQjcS7UP2dgbJNL3dbyKB","eventName":"Labeler"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Dieta Portal","start_url":"/","background_color":"#DDDDDD","theme_color":"#8F82F4","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d3a8a21c7cc0d0dbc67fc3829217bc74"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/davidtiede/Apps/dieta/repos/web-dieta-stool-labeler/src/screens/organisms/Layout/index.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
