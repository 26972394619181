import firebase from "firebase/app"
import "firebase/auth";
import 'firebase/firestore'
import 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyAH-xiABtu_wYtLEXKiRwWV8nHPdLyrqog",
  authDomain: "seventh-magnet-235901.firebaseapp.com",
  databaseURL: "https://seventh-magnet-235901.firebaseio.com",
  projectId: "seventh-magnet-235901",
  storageBucket: "seventh-magnet-235901.appspot.com",
  messagingSenderId: "1017015517557",
  appId: "1:1017015517557:web:4faa4c129189ca25cc933c"
}

try {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)
  firebase.firestore();
  console.log("Firebase Initialized");
} catch (err) {
  console.log("Error Initializing Firebase");
}

export default firebase